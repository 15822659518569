import React, { FC, RefObject, useEffect, useRef, useState } from 'react'
import {
	CheckboxList,
	RadioList,
	RequestFormPDFProps,
	RequestInputFiles,
	RequestInputs,
	UpdateRequestInputsArray,
	SelectList,
	Steps as DefaultSteps,
	StepsUrbanism
} from '@utils/request'
import requestForm from '@components/requestForms/request.form'
import { Collapse as ReactCollapse } from 'react-collapse'
import Collapse from '@components/ui/collapse'
import {
	REQUEST_CONFIGS_KEYS,
	REQUEST_TN_DETAILS_STEPS_NAME,
	Direction,
	requestCategories,
	documentStatusConfig,
	requestSubCategories
} from '@services/constants'
import * as pageUtils from './__forms.utils'
import { useAppSelector } from '@services/store'
import TextInput from './inputs/text.inputs'
import { joinClasses, makeClasses } from '@utils/styles'
import { Colors } from '@utils/css-variables'
import Breakpoints from '@utils/breakpoints'
import { AddressCoordinates, CustomDate } from '@services/models'
import { formatStrapiText, getFilesFromStore } from '@utils/methods'
import * as formUtils from '@pages/auth/__index.utils'
import {
	PHONE_NUMBER_REGEX,
	POSTAL_CODE_REGEX
} from '@pages/auth/profile/__index.utils'
import MaskedTextInput from '@components/requestForms/inputs/textWithValidation.inputs'
import Text from '@components/ui/text'
import RequestFiles from '@components/ui/requestFiles'
import DateFilterInput from './inputs/datefilter.input'
import RadioInput from './inputs/radio.input'
import AddressInfoSection from '@components/ui/addressInfosSection'
import Notification from '@components/ui/notification'
import TextAreaInput from './inputs/textarea.inputs'
import CheckboxInput from './inputs/checkbox.input'
import parse from 'html-react-parser'
import { formatHtmlForStrapiText } from '@utils/methods'
import SelectInput from './inputs/select.inputs'
import { getAddressByCoordinates } from '@components/ui/addressForm/__index.utils'
import AddressVoiePublicSection from '@components/ui/addressVoiePublicSection'
import MaskedThousandsSeparatorInput from './inputs/textWithThousandsSeparator.inputs'
import { formatNumberWithSpaces } from './__forms.utils'

const BuildingDemolitionOrMovingPermitForm: FC<RequestFormPDFProps> = ({
	inputs,
	errors,
	subSteps,
	inputFiles,
	isSummaryStep,
	steps,
	toPrint,
	filesInputs,
	formPosition,
	setDisableNextButton,
	onCustomError,
	onSetInputFiles,
	onSetSubSteps,
	onFixError,
	onSetInputs,
	setCurrentStep,
	onSetCurrentSubStep,
	setStepsList,
	setSteps
}) => {
	const {
		authUser,
		pageAssets,
		UpdateRequestInputsArray,
		UpdateRequestInputFilesArray,
		UpdateRequestInputsErrorsArray
	} = requestForm()

	type Classes = {
		button: string
		outlinedButton: string
		buttonContainer: string
		fileHelperSection: string
		fileContainer: string
		inputContainer: string
		checkbox2: string
		checkbox2Label: string
		checkbox2Container: string
		checkboxWrapper: string
		adresseBlock: string
		h5: string
		greyBackgroundBlock: string
		detailscontainer: string
		subContent: string
		reducedMarginBottom: string
		image: string
	}

	const classes: Classes = makeClasses({
		button: {
			display: 'inline-block',
			padding: '10px 20px',
			fontSize: '13px',
			fontWeight: '500',
			color: Colors.white,
			lineHeight: '1em',
			borderRadius: '23px',
			transition: 'all 0.35s ease',
			MsTransition: 'all 0.35s ease',
			background: Colors.secondary,
			border: 'none',
			cursor: 'pointer',
			'&:hover': {
				background: Colors.darkBlue2,
				borderColor: Colors.darkBlue2,
				color: Colors.white
			}
		},
		outlinedButton: {
			background: 'none',
			border: `1px solid ${Colors.secondary} !important`,
			color: Colors.secondary
		},

		buttonContainer: {
			display: 'flex',
			width: 'fit-content',
			columnGap: '4px'
		},
		fileHelperSection: {
			background: Colors.darkWhite,
			padding: '30px',
			marginBottom: '15px'
		},
		fileContainer: {
			'.blue-bullets-list': {
				ul: {
					padding: '0px 15px 0px 20px'
				}
			}
		},
		inputContainer: {
			marginBottom: '20px'
		},

		checkbox2: {
			width: 'fit-content',
			position: 'absolute',
			verticalAlign: 'baseline',
			clip: 'rect(1px, 1px, 1px, 1px)'
		},
		checkbox2Label: {
			fontSize: '16px',
			padding: '0',
			color: Colors.lightBlack,
			position: 'relative',
			paddingLeft: '27px',
			fontWeight: 'normal',
			cursor: 'pointer',
			lineHeight: '17px',
			':before': {
				content: "''",
				position: 'absolute',
				top: '0',
				left: '0',
				width: '16px',
				height: '16px',
				border: `1px solid ${Colors.lightGrey3}`,
				background: Colors.white,
				outline: 'none !important'
			},
			':after': {
				content: "''",
				position: 'absolute',
				top: '4px',
				left: '4px',
				width: '10px',
				height: '10px',
				background: Colors.secondary,
				outline: 'none',
				opacity: '0',
				transition: 'all .15s ease-out'
			}
		},
		checkbox2Container: {
			marginTop: '-15px',
			marginBottom: '15px',
			'& input[value=true] ~ label:after': {
				opacity: '.99'
			}
		},
		checkboxWrapper: {
			display: 'block',
			margin: '0 0 5px 0',
			background: Colors.ligthGrey2,
			border: `1px solid ${Colors.greyAlto}`,
			padding: '14px 12px'
		},
		adresseBlock: {
			border: `1px solid ${Colors.greyAlto}`,
			padding: '20px 25px',
			marginBottom: '10px',
			'& div': {
				alignItems: 'start !important'
			}
		},
		h5: {
			fontSize: '20px',
			fontWeight: '600',
			margin: '15px 0 10px 0'
		},
		greyBackgroundBlock: {
			background: Colors.darkWhite,
			padding: '15px 30px'
		},
		detailscontainer: {
			display: 'grid',
			alignItems: 'start',
			gridTemplateColumns: '50%43%',
			columnGap: '7%',
			[Breakpoints.maxWidth('lg')]: {
				gridTemplateColumns: '45%46%',
				columnGap: '12%'
			},
			[Breakpoints.maxWidth('md')]: {
				gridTemplateColumns: '100%',
				'& img': {
					marginTop: '15px !important',
					width: '80% !important'
				}
			},
			[Breakpoints.maxWidth('sm')]: {
				'& img': {
					width: '100% !important'
				}
			},
			lineHeight: '1.62em',
			// fontSize: '16px',
			color: Colors.lightBlack,
			'& img': {
				width: '100%',
				verticalAlign: 'middle',
				margin: 'auto'
			},
			'& ul': {
				paddingLeft: '18px',
				margin: 0,
				'& li::marker': {
					color: Colors.secondary
				}
			}
		},
		subContent: {
			display: 'grid',
			columnGap: '15px',
			rowGap: '10px',
			gridTemplateColumns: '2fr auto',
			alignItems: 'end',
			[Breakpoints.maxWidth('md')]: {
				gridTemplateColumns: '2fr auto'
			},
			[Breakpoints.maxWidth('sm')]: {
				gridTemplateColumns: '2fr auto'
			}
		},
		reducedMarginBottom: {
			marginBottom: '18px'
		},
		image: {
			[Breakpoints.maxWidth('md')]: {
				width: '35%'
			}
		}
	})

	const answers = useAppSelector((state) => state.request.configs)
	const location = useAppSelector((state) => state.request.location)

	const isNewConstruction = (value) =>
		value?.toString() ==
		REQUEST_CONFIGS_KEYS.whatToDoBuildingConstructionOrAdditionPermit
			.request_new_construction

	const isAddition = (value) =>
		value?.toString() ==
		REQUEST_CONFIGS_KEYS.whatToDoBuildingConstructionOrAdditionPermit
			.request_addition

	const [showAdresseBlock, setShowAdresseBlock] = useState<boolean>(
		answers?.buildingAddressSameHasApplicant?.toString() == 'false'
			? true
			: false
	)

	const isDemolition = (value) =>
		value?.toString() ==
		REQUEST_CONFIGS_KEYS.whatToDoBuildingDemolitionOrMovingPermit
			.request_demolition

	const isMoving = (value) =>
		value?.toString() ==
		REQUEST_CONFIGS_KEYS.whatToDoBuildingDemolitionOrMovingPermit.request_moving

	//----------------------------------------------------------------
	//--------------------- DETAILS 1 INPUTS -------------------------
	//----------------------------------------------------------------

	// ************* Type de demande ***********

	// Que souhaitez-vous faire?
	const [whatToDoInput, setWhatToDoInput] = useState<RequestInputs>({
		name: 'whatToDo',
		label: pageAssets?.request_form_what_would_you_like_to_do,
		labelKey: 'request_form_what_would_you_like_to_do',
		value: answers?.whatToDo?.toString() || '',
		required: true,
		ref: useRef<HTMLSelectElement>(null),
		subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_1
	})

	// buildingDemolitionOrMovingPermit
	const radioListWhatToDo: RadioList[] = [
		{
			label:
				pageAssets?.request_form_buildingDemolitionOrMovingPermit_request_demolition,
			value:
				REQUEST_CONFIGS_KEYS.whatToDoBuildingDemolitionOrMovingPermit
					.request_demolition,
			key: 'request_form_buildingDemolitionOrMovingPermit_request_demolition',
			extraText:
				pageAssets?.request_form_buildingDemolitionOrMovingPermit_request_demolition_extra_text
		},
		{
			label:
				pageAssets?.request_form_buildingDemolitionOrMovingPermit_request_moving,
			value:
				REQUEST_CONFIGS_KEYS.whatToDoBuildingDemolitionOrMovingPermit
					.request_moving,
			key: 'request_form_buildingDemolitionOrMovingPermit_request_moving',
			extraText:
				pageAssets?.request_form_buildingDemolitionOrMovingPermit_request_moving_extra_text
		}
	]

	// Q2 Vous faites cette demande en tant que:
	const [requestAsInput, setRequestAsInput] = useState<RequestInputs>({
		name: 'requestAs',
		label: pageAssets?.request_form_request_as,
		labelKey: 'request_form_request_as',
		value: answers?.requestAs?.toString() || '',
		required: true,
		ref: useRef<HTMLSelectElement>(null),
		subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_1
	})

	const radioListRequestAs: RadioList[] = [
		{
			label: pageAssets?.request_form_individual,
			value: REQUEST_CONFIGS_KEYS.requestAs.individual,
			key: 'request_form_individual'
		},
		{
			label: pageAssets?.request_form_entreprise,
			value: REQUEST_CONFIGS_KEYS.requestAs.entreprise,
			key: 'request_form_entreprise'
		}, //Entrepreneur or manager
		{
			label: pageAssets?.request_form_entrepreneur_or_manager,
			value: REQUEST_CONFIGS_KEYS.requestAs.entrepreneur_or_manager,
			key: 'request_form_entrepreneur_or_manager'
		}
	]

	// ************* Informations relatives à l'entreprise ***********

	// Nom de l'entreprise
	const [companyNameInput, setCompanyNameInput] = useState<RequestInputs>({
		name: 'companyName',
		label:
			pageAssets?.request_form_annualRegistrationCertificateForContractors_companyName_label,
		labelKey:
			'request_form_annualRegistrationCertificateForContractors_companyName_label',
		value: answers.companyName || '',
		required: [
			REQUEST_CONFIGS_KEYS.requestAs.entreprise,
			REQUEST_CONFIGS_KEYS.requestAs.entrepreneur_or_manager
		].includes(answers.requestAs?.toString() || ''),
		ref: useRef<HTMLSelectElement>(null),
		subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_1
	})

	// Nom et prénom du représentant
	const [representativeNameInput, setRepresentativeNameInput] =
		useState<RequestInputs>({
			name: 'representativeName',
			label:
				pageAssets?.request_form_swimmingPoolSpaPermit_representativeName_label,
			labelKey: 'request_form_swimmingPoolSpaPermit_representativeName_label',
			value: answers.representativeName || '',
			required: [
				REQUEST_CONFIGS_KEYS.requestAs.entreprise,
				REQUEST_CONFIGS_KEYS.requestAs.entrepreneur_or_manager
			].includes(answers.requestAs?.toString() || ''),
			ref: useRef<HTMLSelectElement>(null),
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_1
		})

	const radioListYesNo: RadioList[] = [
		{
			label: pageAssets.request_form_select_yes,
			value: 'true',
			key: 'request_form_select_yes'
		},
		{
			label: pageAssets.request_form_select_no,
			value: 'false',
			key: 'request_form_select_no'
		}
	]

	// Plan d'implantation et d'intégration architecturale (PIIA)

	// Est-ce que les travaux sont assujettis à une approbation PIIA?
	// Is the work subject to PIIA approval?
	const [isSubjectToPiiaApprovalInput, setIsSubjectToPiiaApprovalInput] =
		useState<RequestInputs>({
			name: 'isSubjectToPiiaApproval',
			label:
				pageAssets?.request_form_buildingConstructionOrAdditionPermit_isSubjectToPiiaApproval_label,
			labelKey:
				'request_form_buildingConstructionOrAdditionPermit_isSubjectToPiiaApproval_label',
			description:
				pageAssets?.request_form_buildingConstructionOrAdditionPermit_isSubjectToPiiaApproval_description,
			value: answers.isSubjectToPiiaApproval?.toString() || '',
			required: isMoving(answers?.whatToDo),
			ref: useRef<HTMLInputElement>(null),
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_1
		})

	// Le projet a-t-il reçu une approbation PIIA?
	// Has the project received PIIA approval?
	const [hasBeenSubjectToApprovalInput, setHasBeenSubjectToApprovalInput] =
		useState<RequestInputs>({
			name: 'hasBeenSubjectToApproval',
			label:
				pageAssets?.request_form_buildingConstructionOrAdditionPermit_hasBeenSubjectToApproval_label,
			labelKey:
				'request_form_buildingConstructionOrAdditionPermit_hasBeenSubjectToApproval_label',
			value: answers.hasBeenSubjectToApproval?.toString() || '',
			required:
				isMoving(answers?.whatToDo) &&
				answers.isSubjectToPiiaApproval?.toString() == radioListYesNo[0].value,
			ref: useRef<HTMLInputElement>(null),
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_1
		})

	// 	IA number (architectural integration)
	const [iaNumberInput, setIaNumberInput] = useState<RequestInputs>({
		name: 'iaNumber',
		label:
			pageAssets?.request_form_buildingConstructionOrAdditionPermit_iaNumber_label,
		labelKey:
			'request_form_buildingConstructionOrAdditionPermit_iaNumber_label',
		value: answers.iaNumber || '',
		required:
			isMoving(answers?.whatToDo) &&
			answers.hasBeenSubjectToApproval?.toString() == radioListYesNo[0].value &&
			answers.isSubjectToPiiaApproval?.toString() == radioListYesNo[0].value,
		ref: useRef<HTMLInputElement>(null),
		subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_1
	})

	// Executive Committee (EC) resolution number
	const [ecResolutionNumberInput, setEcResolutionNumberInput] =
		useState<RequestInputs>({
			name: 'ecResolutionNumber',
			label:
				pageAssets?.request_form_buildingConstructionOrAdditionPermit_ecResolutionNumber_label,
			labelKey:
				'request_form_buildingConstructionOrAdditionPermit_ecResolutionNumber_label',
			value: answers.ecResolutionNumber || '',
			required: false,
			ref: useRef<HTMLInputElement>(null),
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_1
		})

	//----------------------------------------------------------------
	//-------------------- SubStep DETAILS_2 INPUTS ------------------
	//----------------------------------------------------------------

	const [isAddressOutsideLaval, setIsAddressOutsideLaval] =
		useState<boolean>(false)

	// hidden input cadastre
	const [buildingBatchNumberInput, setBuildingBatchNumberInput] =
		useState<RequestInputs>({
			name: 'buildingBatchNumber',
			label: '',
			labelKey: '',
			value: answers.buildingBatchNumber || '',
			required: false,
			ref: useRef<HTMLSelectElement>(null)
		})

	const [isSameLocationInput, setIsSameLocationInput] = useState<RequestInputs>(
		{
			name: 'buildingAddressSameHasApplicant',
			label:
				pageAssets?.request_form_buildingConstructionOrAdditionPermit_isSameLocation,
			labelKey:
				'request_form_buildingConstructionOrAdditionPermit_isSameLocation',
			value: answers?.buildingAddressSameHasApplicant?.toString() || '',
			required: true,
			ref: useRef<HTMLSelectElement>(null),
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_2
		}
	)

	const radioListAddressOption: RadioList[] = [
		{
			label:
				pageAssets.request_form_addressInfoSection_addressOption_secondOption_label,
			value: REQUEST_CONFIGS_KEYS.researchType.cadastralNumber,
			key: 'request_form_addressInfoSection_addressOption_secondOption_label'
		},
		{
			label:
				pageAssets.request_form_addressInfoSection_addressOption_firstOption_label,
			value: REQUEST_CONFIGS_KEYS.researchType.address,
			key: 'request_form_addressInfoSection_addressOption_firstOption_label'
		}
	]

	const [isLocationOwnedInput, setIsLocationOwnedInput] =
		useState<RequestInputs>({
			name: 'isLocationOwned',
			label: pageAssets?.request_form_swimmingPoolSpaPermit_isLocationOwned,
			labelKey: 'request_form_swimmingPoolSpaPermit_isLocationOwned',
			value: answers?.isLocationOwned?.toString() || '',
			required: true,
			ref: useRef<HTMLSelectElement>(null),
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_2
		})

	//****** Owner inputs ******

	const [ownerFirstNameInput, setOwnerFirstNameInput] = useState<RequestInputs>(
		{
			name: 'ownerFirstName',
			label: pageAssets?.label_firstName,
			labelKey: 'label_firstName',
			value: answers.ownerFirstName || '',
			required: answers?.isLocationOwned?.toString() == radioListYesNo[1].value,
			ref: useRef<HTMLInputElement>(null),
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_2
		}
	)
	const [ownerLastNameInput, setOwnerLastNameInput] = useState<RequestInputs>({
		name: 'ownerLastName',
		label: pageAssets?.label_lastName,
		labelKey: 'label_lastName',
		value: answers.ownerLastName || '',
		required: answers?.isLocationOwned?.toString() == radioListYesNo[1].value,
		ref: useRef<HTMLInputElement>(null),
		subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_2
	})

	const [ownerEmailInput, setOwnerEmailInput] = useState<RequestInputs>({
		name: 'ownerEmail',
		label: pageAssets?.label_email,
		labelKey: 'label_email',
		value: answers.ownerEmail || '',
		required: answers?.isLocationOwned?.toString() == radioListYesNo[1].value,
		ref: useRef<HTMLInputElement>(null),
		subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_2
	})

	const [ownerPhoneNumberInput, setOwnerPhoneNumberInput] =
		useState<RequestInputs>({
			name: 'ownerPhoneNumber',
			label: pageAssets?.label_telephone,
			labelKey: 'label_telephone',
			value: answers.ownerPhoneNumber || '',
			required: answers?.isLocationOwned?.toString() == radioListYesNo[1].value,
			ref: useRef<HTMLInputElement>(null),
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_2
		})

	// Procuration du propriétaire
	// Owner's power of attorney
	const [firstQuestion, setFirstQuestion] = useState<RequestInputFiles>({
		name: 'firstInput',
		label: pageAssets?.request_form_ownerPowerOfAttorney,
		labelKey: 'request_form_ownerPowerOfAttorney',
		files: new Set<File>(
			filesInputs?.find((el) => el.name == 'firstInput')?.files || []
		),
		description: '',
		required: answers?.isLocationOwned?.toString() == radioListYesNo[1].value,
		numberMaxFiles: 1,
		subStep: REQUEST_TN_DETAILS_STEPS_NAME.DOCUMENTS,
		shouldValidateIn: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_2,
		category: requestCategories.proxy,
		subCategory: requestSubCategories.subCateg2
	})

	////////////////////////////////////////////////////////////////
	//----------------------------------------------------------------
	//------------------- SubStep DETAILS_3 INPUTS -------------------
	//---------------------------------------------------------------

	//  "Quel est l'usage du bâtiment principal existant?"
	// Question 8
	const [mainUseOfExistingBuildingInput, setMainUseOfExistingBuildingInput] =
		useState<RequestInputs>({
			name: 'mainUseOfExistingBuilding',
			label:
				pageAssets?.request_form_buildingConstructionOrAdditionPermit_mainUseOfExistingBuilding,
			labelKey:
				'request_form_buildingConstructionOrAdditionPermit_mainUseOfExistingBuilding',
			value: answers?.mainUseOfExistingBuilding?.toString() || '',
			required: true,
			ref: useRef<HTMLInputElement>(null),
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_3
		})

	const radioListMainUseOfExistingBuilding: RadioList[] = pageUtils
		.radioListMainUseOfExistingBuilding(pageAssets)
		.slice(0, 4)

	// Quel est le type de structure?
	const [structureTypeInput, setStructureTypeInput] = useState<RequestInputs>({
		name: 'existingMainBuildingStructureType',
		label:
			pageAssets?.request_form_buildingDemolitionOrMovingPermit_structureType,
		labelKey: 'request_form_buildingDemolitionOrMovingPermit_structureType',
		value: answers?.existingMainBuildingStructureType?.toString() || '',
		required: true,
		ref: useRef<HTMLSelectElement>(null),
		subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_3
	})

	const radioListStructureType: RadioList[] =
		pageUtils.radioListStructureType(pageAssets)

	// Le bâtiment principal existant est-il...
	const [isTheNewBuildingInput, setIsTheNewBuildingInput] =
		useState<RequestInputs>({
			name: 'existingMainBuildingIs',
			label:
				pageAssets?.request_form_buildingDemolitionOrMovingPermit_isTheNewBuilding,
			labelKey:
				'request_form_buildingDemolitionOrMovingPermit_isTheNewBuilding',
			value: answers?.existingMainBuildingIs?.toString() || '',
			required: true,
			ref: useRef<HTMLSelectElement>(null),
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_3
		})

	const radioListIsTheNewBuilding: RadioList[] = [
		{
			label:
				pageAssets?.request_form_buildingConstructionOrAdditionPermit_isTheNewBuilding_option1,
			value: REQUEST_CONFIGS_KEYS.isTheNewBuilding.firstOption,
			key: 'request_form_buildingConstructionOrAdditionPermit_isTheNewBuilding_option1'
		},
		{
			label:
				pageAssets?.request_form_buildingConstructionOrAdditionPermit_isTheNewBuilding_option2,
			value: REQUEST_CONFIGS_KEYS.isTheNewBuilding.secondOption,
			key: 'request_form_buildingConstructionOrAdditionPermit_isTheNewBuilding_option2'
		},
		{
			label:
				pageAssets?.request_form_buildingConstructionOrAdditionPermit_isTheNewBuilding_option3,
			value: REQUEST_CONFIGS_KEYS.isTheNewBuilding.thirdOption,
			key: 'request_form_buildingConstructionOrAdditionPermit_isTheNewBuilding_option3'
		}
	]

	// Le bâtiment principal existant est-il ou fait-il partie d'une copropriété?
	const [
		isTheNewBuildingPartOfAPropertyInput,
		setIsTheNewBuildingPartOfAPropertyInput
	] = useState<RequestInputs>({
		name: 'existingMainBuildingIsShared',
		label:
			pageAssets?.request_form_buildingDemolitionOrMovingPermit_isTheNewBuildingPartOfAProperty,
		labelKey:
			'request_form_buildingDemolitionOrMovingPermit_isTheNewBuildingPartOfAProperty',
		value: answers?.existingMainBuildingIsShared?.toString() || '',
		required: true,
		ref: useRef<HTMLSelectElement>(null),
		subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_3
	})

	// Autorisation des administrateurs
	const [secondQuestion, setSecondQuestion] = useState<RequestInputFiles>({
		name: 'secondInput',
		label:
			pageAssets?.request_form_buildingDemolitionOrMovingPermit_adminAutorisation,
		labelKey: 'request_form_buildingDemolitionOrMovingPermit_adminAutorisation',
		description: '',
		files: new Set<File>(
			filesInputs?.find((el) => el.name == 'secondInput')?.files || []
		),
		required:
			answers?.existingMainBuildingIsShared?.toString() ==
			radioListYesNo[0].value,
		numberMaxFiles: 1,
		subStep: REQUEST_TN_DETAILS_STEPS_NAME.DOCUMENTS,
		shouldValidateIn: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_3,
		category: requestCategories.other,
		subCategory: requestSubCategories.subCateg1
	})

	////////////////////////////////////////////////////////////////
	//----------------------------------------------------------------
	//------------------- SubStep DETAILS_4 INPUTS -------------------
	//---------------------------------------------------------------

	//-------------------- Informations sur la DEMOLITION INPUTS ------------------------

	// Quel type de bâtiment souhaitez-vous démolir?
	const [typeOfBuildingToDemolishInput, setTypeOfBuildingToDemolishInput] =
		useState<RequestInputs>({
			name: 'typeOfBuildingToDemolish',
			label:
				pageAssets?.request_form_buildingDemolitionOrMovingPermit_typeOfBuildingToDemolish,
			labelKey:
				'request_form_buildingDemolitionOrMovingPermit_typeOfBuildingToDemolish',
			value: answers?.typeOfBuildingToDemolish?.toString() || '',
			required: isDemolition(answers?.whatToDo),
			ref: useRef<HTMLSelectElement>(null),
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_4
		})

	const radioListTypeOfBuildingToDemolish: RadioList[] = [
		{
			label:
				pageAssets?.request_form_buildingDemolitionOrMovingPermit_typeOfBuildingToDemolish_option1,
			value: REQUEST_CONFIGS_KEYS.typeOfBuildingToDemolish.firstOption,
			key: 'request_form_buildingDemolitionOrMovingPermit_typeOfBuildingToDemolish_option1'
		},
		{
			label:
				pageAssets?.request_form_buildingDemolitionOrMovingPermit_typeOfBuildingToDemolish_option2,
			value: REQUEST_CONFIGS_KEYS.typeOfBuildingToDemolish.secondOption,
			key: 'request_form_buildingDemolitionOrMovingPermit_typeOfBuildingToDemolish_option2'
		},
		{
			label:
				pageAssets?.request_form_buildingConstructionOrAdditionPermit_foundationType_option4,
			value: REQUEST_CONFIGS_KEYS.typeOfBuildingToDemolish.thirdOption,
			key: 'request_form_buildingConstructionOrAdditionPermit_foundationType_option4'
		}
	]

	// Veuillez préciser (TypeOfBuildingToDemolishPrecision)
	const [
		typeOfBuildingToDemolishPrecisionInput,
		setTypeOfBuildingToDemolishPrecisionInput
	] = useState<RequestInputs>({
		name: 'typeOfBuildingToDemolishPrecision',
		label:
			pageAssets?.request_form_buildingConstructionOrAdditionPermit_workDueToDisasterPrecision,
		labelKey:
			'request_form_buildingConstructionOrAdditionPermit_workDueToDisasterPrecision',
		value: answers?.typeOfBuildingToDemolishPrecision?.toString() || '',
		required:
			isDemolition(answers?.whatToDo) &&
			answers?.typeOfBuildingToDemolish?.toString() ==
				REQUEST_CONFIGS_KEYS.typeOfBuildingToDemolish.thirdOption,
		ref: useRef<HTMLSelectElement>(null),
		subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_4
	})

	// Effectuez-vous...
	const [demolitionTypeInput, setDemolitionTypeInput] = useState<RequestInputs>(
		{
			name: 'demolitionType',
			label:
				pageAssets?.request_form_buildingDemolitionOrMovingPermit_demolitionType,
			labelKey: 'request_form_buildingDemolitionOrMovingPermit_demolitionType',
			value: answers?.demolitionType?.toString() || '',
			required: isDemolition(answers?.whatToDo),
			ref: useRef<HTMLSelectElement>(null),
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_4
		}
	)

	const radioListDemolitionType: RadioList[] = [
		{
			label:
				pageAssets?.request_form_buildingDemolitionOrMovingPermit_demolitionType_option1,
			value: REQUEST_CONFIGS_KEYS.demolitionType.firstOption,
			key: 'request_form_buildingDemolitionOrMovingPermit_demolitionType_option1'
		},
		{
			label:
				pageAssets?.request_form_buildingDemolitionOrMovingPermit_demolitionType_option2,
			value: REQUEST_CONFIGS_KEYS.demolitionType.secondOption,
			key: 'request_form_buildingDemolitionOrMovingPermit_demolitionType_option2'
		}
	]

	// en tant que dev

	//-------------------- Informations sur le DEPLACAMENT INPUTS ------------------------
	// Quel type de bâtiment souhaitez-vous déplacer?
	const [typeOfBuildingToMoveInput, setTypeOfBuildingToMoveInput] =
		useState<RequestInputs>({
			name: 'typeOfBuildingToMove',
			label:
				pageAssets?.request_form_buildingDemolitionOrMovingPermit_typeOfBuildingToMove,
			labelKey:
				'request_form_buildingDemolitionOrMovingPermit_typeOfBuildingToMove',
			value: answers?.typeOfBuildingToMove?.toString() || '',
			required: isMoving(answers?.whatToDo),
			ref: useRef<HTMLSelectElement>(null),
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_4
		})

	const [
		typeOfBuildingToMovePrecisionInput,
		setTypeOfBuildingToMovePrecisionInput
	] = useState<RequestInputs>({
		name: 'typeOfBuildingToMovePrecision',
		label:
			pageAssets?.request_form_buildingConstructionOrAdditionPermit_workDueToDisasterPrecision,
		labelKey:
			'request_form_buildingConstructionOrAdditionPermit_workDueToDisasterPrecision',
		value: answers?.typeOfBuildingToMovePrecision?.toString() || '',
		required:
			isMoving(answers?.whatToDo) &&
			answers?.typeOfBuildingToMove?.toString() ==
				REQUEST_CONFIGS_KEYS.typeOfBuildingToDemolish.thirdOption,
		ref: useRef<HTMLSelectElement>(null),
		subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_4
	})

	// Déplacez-vous le bâtiment...
	const [isTheBuildingToMoveInput, setIsTheBuildingToMoveInput] =
		useState<RequestInputs>({
			name: 'buildingToMoveIs',
			label:
				pageAssets?.request_form_buildingDemolitionOrMovingPermit_isTheBuildingToMove,
			labelKey:
				'request_form_buildingDemolitionOrMovingPermit_isTheBuildingToMove',
			value: answers?.buildingToMoveIs?.toString() || '',
			required: isMoving(answers?.whatToDo),
			ref: useRef<HTMLSelectElement>(null),
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_4
		})

	const radioListIsTheBuildingToMove: RadioList[] = [
		{
			label:
				pageAssets?.request_form_buildingDemolitionOrMovingPermit_isTheBuildingToMove_option1,
			value: REQUEST_CONFIGS_KEYS.isTheBuildingToMove.firstOption,
			key: 'request_form_buildingDemolitionOrMovingPermit_isTheBuildingToMove_option1'
		},
		{
			label:
				pageAssets?.request_form_buildingDemolitionOrMovingPermit_isTheBuildingToMove_option2,
			value: REQUEST_CONFIGS_KEYS.isTheBuildingToMove.secondOption,
			key: 'request_form_buildingDemolitionOrMovingPermit_isTheBuildingToMove_option2'
		}
	]

	// Le terrain est-il situé sur le territoire de la Ville de Laval?
	const [isLocationInLavalInput, setIsLocationInLavalInput] =
		useState<RequestInputs>({
			name: 'isLocationInLaval',
			label:
				pageAssets?.request_form_buildingDemolitionOrMovingPermit_isLocationInLaval,
			labelKey:
				'request_form_buildingDemolitionOrMovingPermit_isLocationInLaval',
			value: answers?.isLocationInLaval?.toString() || '',
			required: isMoving(answers?.whatToDo),
			ref: useRef<HTMLSelectElement>(null),
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_4
		})

	// isLocationInLavalPrecision
	const [isLocationInLavalPrecisionInput, setIsLocationInLavalPrecisionInput] =
		useState<RequestInputs>({
			name: 'isLocationInLavalPrecision',
			label: pageAssets?.request_form_location,
			labelKey: 'request_form_location',
			value: answers?.isLocationInLavalPrecision?.toString() || '',
			required:
				isMoving(answers?.whatToDo) &&
				answers?.isLocationInLaval?.toString() == radioListYesNo[1].value,
			ref: useRef<HTMLSelectElement>(null),
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_4
		})

	// Quelle est la raison du déplacement du bâtiment?
	const [reasonForMovingBuildingInput, setReasonForMovingBuildingInput] =
		useState<RequestInputs>({
			name: 'reasonForMovingBuilding',
			label:
				pageAssets?.request_form_buildingDemolitionOrMovingPermit_reasonForMovingBuilding,
			labelKey:
				'request_form_buildingDemolitionOrMovingPermit_reasonForMovingBuilding',
			value: answers?.reasonForMovingBuilding?.toString() || '',
			required: isMoving(answers?.whatToDo),
			ref: useRef<HTMLSelectElement>(null),
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_4
		})

	// ----------------------------------------------------------------
	//-------------------- Informations sur les travaux INPUTS ------------------------

	// -------------------  DETAILS_5 -------------------

	// Effectuez-vous ces travaux à la suite d'une catastrophe?
	const [Q24IsWorkDueToDisasterInput, setQ24IsWorkDueToDisasterInput] =
		useState<RequestInputs>({
			name: 'isWorkDueToDisaster',
			label:
				pageAssets?.request_form_buildingConstructionOrAdditionPermit_isWorkDueToDisaster,
			labelKey:
				'request_form_buildingConstructionOrAdditionPermit_isWorkDueToDisaster',
			value: answers?.isWorkDueToDisaster?.toString() || '',
			required: true,
			ref: useRef<HTMLSelectElement>(null),
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_5
		})

	// Veuillez préciser WorkDueToDisasterPrecision
	const [
		Q24_1WorkDueToDisasterPrecisionInput,
		setQ24_1WorkDueToDisasterPrecisionInput
	] = useState<RequestInputs>({
		name: 'workDueToDisasterPrecision',
		label:
			pageAssets?.request_form_buildingConstructionOrAdditionPermit_workDueToDisasterPrecision,
		labelKey:
			'request_form_buildingConstructionOrAdditionPermit_workDueToDisasterPrecision',
		value: answers?.workDueToDisasterPrecision?.toString() || '',
		required: Q24IsWorkDueToDisasterInput.value == radioListYesNo[0].value,
		ref: useRef<HTMLSelectElement>(null),
		subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_5
	})

	// Coût approximatif des travaux
	const [Q25ApproximateCostOfWorkInput, setQ25ApproximateCostOfWorkInput] =
		useState<RequestInputs>({
			name: 'approximateCostOfWork',
			label:
				pageAssets?.request_form_buildingConstructionOrAdditionPermit_approximateCostOfWork,
			labelKey:
				'request_form_buildingConstructionOrAdditionPermit_approximateCostOfWork',
			value: answers?.approximateCostOfWork?.toString() || '',
			required: true,
			ref: useRef<HTMLInputElement>(null),
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_5
		})

	// Est-ce qu'un abattage d'arbres est requis?
	const [Q27IsTreeCuttingRequiredInput, setQ27IsTreeCuttingRequiredInput] =
		useState<RequestInputs>({
			name: 'isTreeCuttingRequired',
			label:
				pageAssets?.request_form_buildingConstructionOrAdditionPermit_isTreeCuttingRequired,
			labelKey:
				'request_form_buildingConstructionOrAdditionPermit_isTreeCuttingRequired',
			description:
				pageAssets?.request_form_buildingConstructionOrAdditionPermit_isTreeCuttingRequired_description,
			value: answers?.isTreeCuttingRequired?.toString() || '',
			required: true,
			ref: useRef<HTMLSelectElement>(null),
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_5
		})

	// Renseignements supplémentaires
	const [Q28AdditionalInfoInput, setQ28AdditionalInfoInput] =
		useState<RequestInputs>({
			name: 'additionalInfoQ28',
			label:
				pageAssets?.request_form_buildingConstructionOrAdditionPermit_additionalInfoQ28,
			labelKey:
				'request_form_buildingConstructionOrAdditionPermit_additionalInfoQ28',
			description:
				pageAssets?.request_form_buildingConstructionOrAdditionPermit_additionalInfoQ28_description,
			value: answers?.additionalInfoQ28?.toString() || '',
			required: true,
			ref: useRef<HTMLTextAreaElement>(null),
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_5
		})

	// Date de début (projetée)
	const [Q29StartDateInput, setQ29StartDateInput] = useState<RequestInputs>({
		name: 'workStartDate',
		label:
			pageAssets?.request_form_buildingConstructionOrAdditionPermit_startDate,
		labelKey: 'request_form_buildingConstructionOrAdditionPermit_startDate',
		value: answers.workStartDate || '',
		required: false,
		ref: useRef<HTMLInputElement>(null),
		subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_5
	})

	// Date de fin (projetée)
	const [Q30EndDateInput, setQ30EndDateInput] = useState<RequestInputs>({
		name: 'workEndDate',
		label:
			pageAssets?.request_form_buildingConstructionOrAdditionPermit_endDate,
		labelKey: 'request_form_buildingConstructionOrAdditionPermit_endDate',
		value: answers.workEndDate || '',
		required: false,
		ref: useRef<HTMLInputElement>(null),
		subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_5
	})

	// Entrepreneur exécutant les travaux de construction.

	// Qui sera en charge des travaux?
	const [Q31WhoWillBeInChargeOfWorkInput, setQ31WhoWillBeInChargeOfWorkInput] =
		useState<RequestInputs>({
			name: 'whoWillBeInChargeOfWork',
			label:
				pageAssets?.request_form_buildingConstructionOrAdditionPermit_whoWillBeInChargeOfWork,
			labelKey:
				'request_form_buildingConstructionOrAdditionPermit_whoWillBeInChargeOfWork',
			value: answers?.whoWillBeInChargeOfWork?.toString() || '',
			required: true,
			ref: useRef<HTMLSelectElement>(null),
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_5
		})

	const radioListWhoWillBeInChargeOfWork: RadioList[] =
		pageUtils.radioListWhoWillBeInChargeOfWork(pageAssets)

	// Je ne connais pas encore l'entrepreneur
	const [Q31_1EntrepreneurUnknownInput, setQ31_1EntrepreneurUnknownInput] =
		useState<RequestInputs>({
			name: 'entrepreneurUnknown1',
			label:
				pageAssets?.request_form_buildingConstructionOrAdditionPermit_entrepreneurUnknown,
			labelKey:
				'request_form_buildingConstructionOrAdditionPermit_entrepreneurUnknown',
			value: answers.entrepreneurUnknown1?.toString() || String(false),
			required: true,
			ref: useRef<HTMLInputElement>(null),
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_6
		})

	// Nom de l'entreprise exécutant les travaux de construction.
	const [
		Q32CompanyNameExecutingWorkInput,
		setQ32CompanyNameExecutingWorkInput
	] = useState<RequestInputs>({
		name: 'companyNameExecutingWork',
		label:
			pageAssets?.request_form_buildingConstructionOrAdditionPermit_companyNameExecutingWork,
		labelKey:
			'request_form_buildingConstructionOrAdditionPermit_companyNameExecutingWork',
		value: answers.companyNameExecutingWork || '',
		required:
			answers?.whoWillBeInChargeOfWork ==
				radioListWhoWillBeInChargeOfWork[0].value ||
			answers.entrepreneurUnknown1 == true
				? false
				: true,
		ref: useRef<HTMLInputElement>(null),
		subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_5
	})

	// Numéro d'entreprise du Québec (NEQ)
	const [Q33CompanyNEQNumberInput, setQ33CompanyNEQNumberInput] =
		useState<RequestInputs>({
			name: 'companyNEQNumber',
			label:
				pageAssets?.request_form_buildingConstructionOrAdditionPermit_companyNEQNumber,
			labelKey:
				'request_form_buildingConstructionOrAdditionPermit_companyNEQNumber',
			value: answers.companyNEQNumber || '',
			required:
				answers?.whoWillBeInChargeOfWork ==
					radioListWhoWillBeInChargeOfWork[0].value ||
				answers.entrepreneurUnknown1 == true
					? false
					: true,
			ref: useRef<HTMLInputElement>(null),
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_5
		})

	// Numéro de licence RBQ
	const [Q34CompanyRBQNumberInput, setQ34CompanyRBQNumberInput] =
		useState<RequestInputs>({
			name: 'companyRBQNumber',
			label:
				pageAssets?.request_form_buildingConstructionOrAdditionPermit_companyRBQNumber,
			labelKey:
				'request_form_buildingConstructionOrAdditionPermit_companyRBQNumber',
			value: answers.companyRBQNumber || '',
			required:
				answers?.whoWillBeInChargeOfWork ==
					radioListWhoWillBeInChargeOfWork[0].value ||
				answers.entrepreneurUnknown1 == true
					? false
					: true,
			ref: useRef<HTMLInputElement>(null),
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_5
		})

	// Nom complet du (de la) représentant(e) ou de la personne contact
	const [
		Q35CompanyRepresentativeNameInput,
		setQ35CompanyRepresentativeNameInput
	] = useState<RequestInputs>({
		name: 'companyRepresentativeName',
		label:
			pageAssets?.request_form_buildingConstructionOrAdditionPermit_companyRepresentativeName,
		labelKey:
			'request_form_buildingConstructionOrAdditionPermit_companyRepresentativeName',
		value: answers.companyRepresentativeName || '',
		required:
			answers?.whoWillBeInChargeOfWork ==
				radioListWhoWillBeInChargeOfWork[0].value ||
			answers.entrepreneurUnknown1 == true
				? false
				: true,
		ref: useRef<HTMLInputElement>(null),
		subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_5
	})

	// Téléphone
	const [
		Q36CompanyRepresentativePhoneNumberInput,
		setQ36CompanyRepresentativePhoneNumberInput
	] = useState<RequestInputs>({
		name: 'companyRepresentativePhoneNumber',
		label: pageAssets?.label_telephone,
		labelKey: 'label_telephone',
		value: answers.companyRepresentativePhoneNumber || '',
		required:
			answers?.whoWillBeInChargeOfWork ==
				radioListWhoWillBeInChargeOfWork[0].value ||
			answers.entrepreneurUnknown1 == true
				? false
				: true,
		ref: useRef<HTMLInputElement>(null),
		subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_5
	})

	// Courriel
	const [
		Q37CompanyRepresentativeEmailInput,
		setQ37CompanyRepresentativeEmailInput
	] = useState<RequestInputs>({
		name: 'companyRepresentativeEmail',
		label: pageAssets?.label_email,
		labelKey: 'label_email',
		value: answers.companyRepresentativeEmail || '',
		required:
			answers?.whoWillBeInChargeOfWork ==
				radioListWhoWillBeInChargeOfWork[0].value ||
			answers.entrepreneurUnknown1 == true
				? false
				: true,
		ref: useRef<HTMLInputElement>(null),
		subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_5
	})

	// Adresse de l'entreprise
	const [Q38CompanyAddressInput, setQ38CompanyAddressInput] =
		useState<RequestInputs>({
			name: 'companyAddress',
			label: pageAssets?.label_address,
			labelKey: 'label_address',
			value: answers.companyAddress || '',
			required:
				answers?.whoWillBeInChargeOfWork ==
					radioListWhoWillBeInChargeOfWork[0].value ||
				answers.entrepreneurUnknown1 == true
					? false
					: true,
			ref: useRef<HTMLInputElement>(null),
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_5
		})

	// Ville
	const [Q39CompanyCityInput, setQ39CompanyCityInput] = useState<RequestInputs>(
		{
			name: 'companyCity',
			label: pageAssets?.label_city,
			labelKey: 'label_city',
			value: answers.companyCity || '',
			required:
				answers?.whoWillBeInChargeOfWork ==
					radioListWhoWillBeInChargeOfWork[0].value ||
				answers.entrepreneurUnknown1 == true
					? false
					: true,
			ref: useRef<HTMLInputElement>(null),
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_5
		}
	)

	// Code postal
	const [Q40CompanyPostalCodeInput, setQ40CompanyPostalCodeInput] =
		useState<RequestInputs>({
			name: 'companyPostalCode',
			label: pageAssets?.label_postalCode,
			labelKey: 'label_postalCode',
			value: answers.companyPostalCode || '',
			required:
				answers?.whoWillBeInChargeOfWork ==
					radioListWhoWillBeInChargeOfWork[0].value ||
				answers.entrepreneurUnknown1 == true
					? false
					: true,
			ref: useRef<HTMLInputElement>(null),
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_5
		})

	//----------------------------------------------------------------
	//----------------------- DETAILS_6 INPUTS -----------------------
	//----------------------------------------------------------------

	// Est-ce que des travaux de branchement d'aquéduc et d'égouts sont nécessaires à vos travaux ?
	const [
		Q41IsWaterAndSewerConnectionRequiredInput,
		setQ41IsWaterAndSewerConnectionRequiredInput
	] = useState<RequestInputs>({
		name: 'isWaterAndSewerConnectionRequired',
		label:
			pageAssets?.request_form_buildingConstructionOrAdditionPermit_isWaterAndSewerConnectionRequired,
		labelKey:
			'request_form_buildingConstructionOrAdditionPermit_isWaterAndSewerConnectionRequired',
		value: answers.isWaterAndSewerConnectionRequired?.toString() || '',
		required: true,
		ref: useRef<HTMLSelectElement>(null),
		subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_6
	})

	// Votre lot est-il branché aux conduites principales municipales suivantes?
	const [
		Q41IsPropertyConnectedToMunicipalConduitsInput,
		setQ41IsPropertyConnectedToMunicipalConduitsInput
	] = useState<RequestInputs>({
		name: 'isPropertyConnectedToMunicipalConduits',
		label:
			pageAssets?.request_form_buildingConstructionOrAdditionPermit_isPropertyConnectedToMunicipalConduits,
		labelKey:
			'request_form_buildingConstructionOrAdditionPermit_isPropertyConnectedToMunicipalConduits',
		value: answers.isPropertyConnectedToMunicipalConduits || '',
		required: false,
		ref: useRef<HTMLSelectElement>(null),
		subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_6
	})

	const radioListIsPropertyConnectedToMunicipalConduits: CheckboxList[] =
		pageUtils.radioListIsPropertyConnectedToMunicipalConduits(pageAssets)

	// Veuillez ajouter des précisions, si nécessaire
	const [Q42AdditionalInfoInput, setQ42AdditionalInfoInput] =
		useState<RequestInputs>({
			name: 'additionalInfoQ42',
			label:
				pageAssets?.request_form_buildingConstructionOrAdditionPermit_additionalInfoQ42,
			labelKey:
				'request_form_buildingConstructionOrAdditionPermit_additionalInfoQ42',
			value: answers.additionalInfoQ42 || '',
			required: false,
			ref: useRef<HTMLInputElement>(null),
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_6
		})

	// Dans le cadre de vos travaux, prévoyez vous
	// As part of your work, you plan to
	const [Q43PlannedWorkInput, setQ43PlannedWorkInput] = useState<RequestInputs>(
		{
			name: 'plannedWorkMovingBuilding',
			label:
				pageAssets?.request_form_buildingConstructionOrAdditionPermit_plannedWork,
			labelKey: 'request_form_buildingConstructionOrAdditionPermit_plannedWork',
			value: answers.plannedWorkMovingBuilding || '',
			required: false,
			ref: useRef<HTMLInputElement>(null),
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_6
		}
	)

	const listPlannedWork: CheckboxList[] =
		pageUtils.listPlannedWorkMovingBuilding(pageAssets)

	// Veuillez ajouter des précisions sur les travaux
	const [Q44AdditionalInfoInput, setQ44AdditionalInfoInput] =
		useState<RequestInputs>({
			name: 'plannedWorkMovingBuildingPrecision',
			label:
				pageAssets?.request_form_buildingConstructionOrAdditionPermit_additionalInfoQ44,
			labelKey:
				'request_form_buildingConstructionOrAdditionPermit_additionalInfoQ44',
			value: answers.plannedWorkMovingBuildingPrecision || '',
			required: false,
			ref: useRef<HTMLInputElement>(null),
			subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_6
		})

	// ----------------------------------------------------------

	const [dummyOptionalInput, setDummyOptionalInput] = useState<RequestInputs>({
		name: 'dummyOptional',
		label: '',
		labelKey: '',
		value: '',
		required: false,
		ref: useRef<HTMLSelectElement>(null),
		subStep: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_6
	})
	//----------------------------------------------------------------
	//----------------------- DOCUMENTS INPUTS -----------------------
	//----------------------------------------------------------------

	// check if isIA
	// to use value from summary answers or from inputForm.value
	const isPIIACategPermitDocument = (isformInputs?: boolean) => {
		if (isformInputs == true) {
			return isSubjectToPiiaApprovalInput.value == 'true' &&
				hasBeenSubjectToApprovalInput.value == 'true'
				? 'isPIIAApprovalReceived'
				: isSubjectToPiiaApprovalInput.value == 'true' &&
				  hasBeenSubjectToApprovalInput.value == 'false'
				? 'isPIIAApprovalNotReceived'
				: 'isNotPIIA'
		}

		return answers?.isSubjectToPiiaApproval?.toString() == 'true' &&
			answers?.hasBeenSubjectToApproval?.toString() == 'true'
			? 'isPIIAApprovalReceived'
			: answers?.isSubjectToPiiaApproval?.toString() == 'true' &&
			  answers?.hasBeenSubjectToApproval?.toString() == 'false'
			? 'isPIIAApprovalNotReceived'
			: 'isNotPIIA'
	}

	const getDocumentCategory = (
		type: string,
		category: string,
		isformInputs?: boolean
	) => {
		if (category == '') {
			console.log('category is empty')

			return []
		}

		if (isPIIACategPermitDocument(isformInputs) == 'isNotPIIA') {
			return documentStatusConfig.buildingDemolitionOrMovingPermit[type][
				'isNotPIIA'
			]
		}

		if (isPIIACategPermitDocument(isformInputs) == 'isPIIAApprovalReceived') {
			return documentStatusConfig.buildingDemolitionOrMovingPermit[type][
				'isPIIA'
			]['isPIIAApprovalReceived']
		}

		if (
			isPIIACategPermitDocument(isformInputs) == 'isPIIAApprovalNotReceived'
		) {
			return documentStatusConfig.buildingDemolitionOrMovingPermit[type][
				'isPIIA'
			]['isPIIAApprovalNotReceived'][category]
		}

		return []
	}

	const getDocumentStatus = (
		type: string,
		category: string | undefined,
		document: string,
		isformInputs?: boolean
	): string => {
		if (!category) {
			return 'not_required'
		}

		const categoryConfig = getDocumentCategory(type, category, isformInputs)

		if (categoryConfig) {
			return categoryConfig[document] || 'not_required'
		}
		return 'not_required'
	}

	const isDocumentToShow = (
		type: string,
		category: string | undefined,
		document: string,
		status: string,
		isformInputs?: boolean
	) => {
		return getDocumentStatus(type, category, document, isformInputs) == status
	}

	// Certificat de localisation
	const [thirdQuestion, setThirdQuestion] = useState<RequestInputFiles>({
		name: 'thirdInput',
		label:
			pageAssets?.request_form_buildingDemolitionOrMovingPermit_locationCertificate,
		labelKey:
			'request_form_buildingDemolitionOrMovingPermit_locationCertificate',
		description:
			pageAssets?.request_form_buildingDemolitionOrMovingPermit_locationCertificate_description,
		files: new Set<File>(
			filesInputs?.find((el) => el.name == 'thirdInput')?.files || []
		),
		required: isDemolition(answers?.whatToDo),
		numberMaxFiles: 5,
		category: requestCategories.other,
		subCategory: requestSubCategories.subCateg1,
		subStep: REQUEST_TN_DETAILS_STEPS_NAME.DOCUMENTS,
		shouldValidateIn: REQUEST_TN_DETAILS_STEPS_NAME.DOCUMENTS
	})

	// Autres documents
	const [fourthQuestion, setFourthQuestion] = useState<RequestInputFiles>({
		name: 'fourthInput',
		label:
			pageAssets?.request_form_buildingConstructionOrAdditionPermit_otherDocuments,
		labelKey:
			'request_form_buildingConstructionOrAdditionPermit_otherDocuments',
		description:
			pageAssets?.request_form_buildingConstructionOrAdditionPermit_otherDocuments_description,
		files: new Set<File>(
			filesInputs?.find((el) => el.name == 'fourthInput')?.files || []
		),
		required: false,
		numberMaxFiles: 5,
		category: requestCategories.other,
		subCategory: requestSubCategories.subCateg1,
		subStep: REQUEST_TN_DETAILS_STEPS_NAME.DOCUMENTS,
		shouldValidateIn: REQUEST_TN_DETAILS_STEPS_NAME.DOCUMENTS
	})

	// Plan d'implantation
	const [fifthQuestion, setFifthQuestion] = useState<RequestInputFiles>({
		name: 'fifthInput',
		label:
			pageAssets?.request_form_buildingConstructionOrAdditionPermit_implementationPlan,
		labelKey:
			'request_form_buildingConstructionOrAdditionPermit_implementationPlan',
		description:
			pageAssets?.request_form_buildingConstructionOrAdditionPermit_implementationPlan_description,
		files: new Set<File>(
			filesInputs?.find((el) => el.name == 'fifthInput')?.files || []
		),
		required:
			getDocumentStatus(
				'MOVING',
				answers?.mainUseOfExistingBuilding,
				'fifthInput'
			) == 'required',
		numberMaxFiles: 1,
		category: requestCategories.other,
		subCategory: requestSubCategories.subCateg1,
		subStep: REQUEST_TN_DETAILS_STEPS_NAME.DOCUMENTS,
		shouldValidateIn: REQUEST_TN_DETAILS_STEPS_NAME.DOCUMENTS
	})

	// Certificat de localisation
	const [sixthQuestion, setSixthQuestion] = useState<RequestInputFiles>({
		name: 'sixthInput',
		label:
			pageAssets?.request_form_buildingDemolitionOrMovingPermit_locationCertificate,
		labelKey:
			'request_form_buildingDemolitionOrMovingPermit_locationCertificate',
		description: '',
		files: new Set<File>(
			filesInputs?.find((el) => el.name == 'sixthInput')?.files || []
		),
		required:
			getDocumentStatus(
				'MOVING',
				answers?.mainUseOfExistingBuilding,
				'sixthInput'
			) == 'required',
		numberMaxFiles: 1,
		category: requestCategories.other,
		subStep: REQUEST_TN_DETAILS_STEPS_NAME.DOCUMENTS,
		shouldValidateIn: REQUEST_TN_DETAILS_STEPS_NAME.DOCUMENTS
	})

	// Photographe
	const [seventhQuestion, setSeventhQuestion] = useState<RequestInputFiles>({
		name: 'seventhInput',
		label:
			pageAssets?.request_form_buildingDemolitionOrMovingPermit_photographerPlan,
		labelKey: 'request_form_buildingDemolitionOrMovingPermit_photographerPlan',
		description: '',
		files: new Set<File>(
			filesInputs?.find((el) => el.name == 'seventhInput')?.files || []
		),
		required:
			getDocumentStatus(
				'MOVING',
				answers?.mainUseOfExistingBuilding,
				'seventhInput'
			) == 'required',
		numberMaxFiles: 1,
		category: requestCategories.other,
		subStep: REQUEST_TN_DETAILS_STEPS_NAME.DOCUMENTS,
		shouldValidateIn: REQUEST_TN_DETAILS_STEPS_NAME.DOCUMENTS
	})

	// Autorisation des services publics
	const [eighthQuestion, setEighthQuestion] = useState<RequestInputFiles>({
		name: 'eighthInput',
		label:
			pageAssets?.request_form_buildingDemolitionOrMovingPermit_authorizationOfPublicServices,
		labelKey:
			'request_form_buildingDemolitionOrMovingPermit_authorizationOfPublicServices,',
		description: '',
		files: new Set<File>(
			filesInputs?.find((el) => el.name == 'eighthInput')?.files || []
		),
		required:
			getDocumentStatus(
				'MOVING',
				answers?.mainUseOfExistingBuilding,
				'eighthInput'
			) == 'required',
		numberMaxFiles: 1,
		category: requestCategories.other,
		subStep: REQUEST_TN_DETAILS_STEPS_NAME.DOCUMENTS,
		shouldValidateIn: REQUEST_TN_DETAILS_STEPS_NAME.DOCUMENTS
	})

	// Plan d'architecture préliminaire
	const [ninthQuestion, setNinthQuestion] = useState<RequestInputFiles>({
		name: 'ninthInput',
		label:
			pageAssets?.request_form_buildingConstructionOrAdditionPermit_preliminaryArchitecturePlan,
		labelKey:
			'request_form_buildingConstructionOrAdditionPermit_preliminaryArchitecturePlan',
		description:
			pageAssets?.request_form_buildingConstructionOrAdditionPermit_preliminaryArchitecturePlan_description,
		files: new Set<File>(
			filesInputs?.find((el) => el.name == 'ninthInput')?.files || []
		),
		required:
			getDocumentStatus(
				'MOVING',
				answers?.mainUseOfExistingBuilding,
				'ninthInput'
			) == 'required',
		numberMaxFiles: 1,
		category: requestCategories.other,
		subStep: REQUEST_TN_DETAILS_STEPS_NAME.DOCUMENTS,
		shouldValidateIn: REQUEST_TN_DETAILS_STEPS_NAME.DOCUMENTS
	})

	// Fiche Bâtiment/Fiche Technique*
	const [tenthQuestion, setTenthQuestion] = useState<RequestInputFiles>({
		name: 'tenthInput',
		label:
			pageAssets?.request_form_buildingConstructionOrAdditionPermit_buildingSheet,
		labelKey: 'request_form_buildingConstructionOrAdditionPermit_buildingSheet',
		description: '',
		files: new Set<File>(
			filesInputs?.find((el) => el.name == 'tenthInput')?.files || []
		),
		required:
			getDocumentStatus(
				'MOVING',
				answers?.mainUseOfExistingBuilding,
				'tenthInput'
			) == 'required',
		numberMaxFiles: 1,
		category: requestCategories.other,
		subStep: REQUEST_TN_DETAILS_STEPS_NAME.DOCUMENTS,
		shouldValidateIn: REQUEST_TN_DETAILS_STEPS_NAME.DOCUMENTS
	})

	// Autres documents
	const [eleventhQuestion, setEleventhQuestion] = useState<RequestInputFiles>({
		name: 'eleventhInput',
		label:
			pageAssets?.request_form_buildingConstructionOrAdditionPermit_otherDocuments,
		labelKey:
			'request_form_buildingConstructionOrAdditionPermit_otherDocuments',
		description:
			pageAssets?.request_form_buildingConstructionOrAdditionPermit_otherDocuments_description,
		files: new Set<File>(
			filesInputs?.find((el) => el.name == 'eleventhInput')?.files || []
		),
		required: false,
		numberMaxFiles: 10,
		category: requestCategories.other,
		subCategory: requestSubCategories.subCateg1,
		subStep: REQUEST_TN_DETAILS_STEPS_NAME.DOCUMENTS,
		shouldValidateIn: REQUEST_TN_DETAILS_STEPS_NAME.DOCUMENTS
	})

	// ------------------- DETAILS INPUTS SETTERS --------------------

	// Reusable function to set input and handle errors
	const handleInputChange = (setInput, input, value, inputName) => {
		setInput({
			...input,
			value
		})

		onFixError(UpdateRequestInputsErrorsArray(errors!, inputName))
	}

	// Reusable hook to update inputs
	const useUpdateInputs = (input) => {
		useEffect(() => {
			onSetInputs(UpdateRequestInputsArray(inputs!, input))
		}, [input])
	}

	const updateInputsRequired = (inputs: any[], value: boolean) => {
		inputs.forEach((input) =>
			input.setState({ ...input.state, required: value })
		)
	}

	const setRequiredDemolitionRequestInputs = (value: boolean) => {
		updateInputsRequired(
			[
				{
					setState: setTypeOfBuildingToDemolishInput,
					state: typeOfBuildingToDemolishInput
				},
				{
					setState: setDemolitionTypeInput,
					state: demolitionTypeInput
				}
			],
			value
		)
	}

	const setRequiredMovingRequestInputs = (value: boolean) => {
		updateInputsRequired(
			[
				{
					setState: setTypeOfBuildingToMoveInput,
					state: typeOfBuildingToMoveInput
				},
				{
					setState: setIsTheBuildingToMoveInput,
					state: isTheBuildingToMoveInput
				},
				{
					setState: setIsLocationInLavalInput,
					state: isLocationInLavalInput
				},
				{
					setState: setReasonForMovingBuildingInput,
					state: reasonForMovingBuildingInput
				}
			],
			value
		)
	}

	const updateMovingRequiredDocuments = (
		mainUseOfNewBuilding: string | undefined,
		isformInputs?: boolean
	) => {
		const category = getDocumentCategory(
			'MOVING',
			mainUseOfNewBuilding?.toString() ?? '',
			isformInputs
		)

		setFifthQuestion({
			...fifthQuestion,
			required: category?.fifthInput == 'required'
		})
		setSixthQuestion({
			...sixthQuestion,
			required: category?.sixthInput == 'required'
		})
		setSeventhQuestion({
			...seventhQuestion,
			required: category?.seventhInput == 'required'
		})
		setEighthQuestion({
			...eighthQuestion,
			required: category?.eighthInput == 'required'
		})
		setNinthQuestion({
			...ninthQuestion,
			required: category?.ninthInput == 'required'
		})
		setTenthQuestion({
			...tenthQuestion,
			required: category?.tenthInput == 'required'
		})
	}

	const onSetWhatToDoInput = (value: string) => {
		setWhatToDoInput({ ...whatToDoInput, value })

		// setRequestTypeTitle(pageAssets?.request_form_information)

		if (
			value ==
			REQUEST_CONFIGS_KEYS.whatToDoBuildingDemolitionOrMovingPermit
				.request_demolition
		) {
			setRequiredDemolitionRequestInputs(true)
			setRequiredMovingRequestInputs(false)

			// files
			setThirdQuestion({ ...thirdQuestion, required: true })
			updateMovingRequiredDocuments('')

			// step Title
			setStepsList &&
				setStepsList(
					REQUEST_STEPS(pageAssets?.request_form_building_demolition)
				)
		}

		if (
			value ==
			REQUEST_CONFIGS_KEYS.whatToDoBuildingDemolitionOrMovingPermit
				.request_moving
		) {
			setRequiredMovingRequestInputs(true)
			setRequiredDemolitionRequestInputs(false)

			// files
			setThirdQuestion({ ...thirdQuestion, required: false })
			updateMovingRequiredDocuments(mainUseOfExistingBuildingInput.value, true)

			// step Title
			setStepsList &&
				setStepsList(REQUEST_STEPS(pageAssets?.request_form_building_moving))
		}

		onFixError(
			errors.filter(
				(error) =>
					![
						whatToDoInput.name,
						isSubjectToPiiaApprovalInput.name,
						hasBeenSubjectToApprovalInput.name,
						iaNumberInput.name,
						typeOfBuildingToDemolishInput.name,
						typeOfBuildingToDemolishPrecisionInput.name,
						demolitionTypeInput.name,
						typeOfBuildingToMoveInput.name,
						isTheBuildingToMoveInput.name,
						isLocationInLavalInput.name,
						reasonForMovingBuildingInput.name
					].includes(error)
			)
		)
	}

	useUpdateInputs(whatToDoInput)
	//------------------------------------------------------------

	const onSetRequestAsInput = (value: string) => {
		setRequestAsInput({ ...requestAsInput, value })

		if (
			[
				REQUEST_CONFIGS_KEYS.requestAs.entreprise,
				REQUEST_CONFIGS_KEYS.requestAs.entrepreneur_or_manager
			].includes(value)
		) {
			setCompanyNameInput({ ...companyNameInput, required: true })
			setRepresentativeNameInput({ ...representativeNameInput, required: true })
		} else {
			setCompanyNameInput({ ...companyNameInput, required: false })
			setRepresentativeNameInput({
				...representativeNameInput,
				required: false
			})
		}

		if (
			value == REQUEST_CONFIGS_KEYS.requestAs.individual &&
			Q31WhoWillBeInChargeOfWorkInput.value ==
				REQUEST_CONFIGS_KEYS.whoWillBeInChargeOfWork.firstOption
		) {
			setQ31WhoWillBeInChargeOfWorkInput({
				...Q31WhoWillBeInChargeOfWorkInput,
				value: ''
			})
		}

		onFixError(
			errors.filter(
				(error) =>
					![
						requestAsInput.name,
						companyNameInput.name,
						representativeNameInput.name,
						Q32CompanyNameExecutingWorkInput.name,
						Q33CompanyNEQNumberInput.name,
						Q34CompanyRBQNumberInput.name,
						Q35CompanyRepresentativeNameInput.name,
						Q36CompanyRepresentativePhoneNumberInput.name,
						Q37CompanyRepresentativeEmailInput.name,
						Q38CompanyAddressInput.name,
						Q39CompanyCityInput.name,
						Q40CompanyPostalCodeInput.name
					].includes(error)
			)
		)
	}

	useUpdateInputs(requestAsInput)

	//------------------------------------------------------------

	const onSetCompanyNameInput = (value: string) => {
		handleInputChange(
			setCompanyNameInput,
			companyNameInput,
			value,
			companyNameInput.name
		)
	}

	useUpdateInputs(companyNameInput)

	//------------------------------------------------------------
	const onSetRepresentativeNameInput = (value: string) => {
		handleInputChange(
			setRepresentativeNameInput,
			representativeNameInput,
			value,
			representativeNameInput.name
		)
	}

	useUpdateInputs(representativeNameInput)

	//------------------------------------------------------------

	const onSetIsSubjectToPiiaApprovalInput = (value: string) => {
		setIsSubjectToPiiaApprovalInput({ ...isSubjectToPiiaApprovalInput, value })

		if (value == radioListYesNo[0].value) {
			setHasBeenSubjectToApprovalInput({
				...hasBeenSubjectToApprovalInput,
				required: true
			})
			// setIaNumberInput({ ...iaNumberInput, required: true })
		} else {
			setHasBeenSubjectToApprovalInput({
				...hasBeenSubjectToApprovalInput,
				required: false,
				value: ''
			})
			setIaNumberInput({ ...iaNumberInput, required: false, value: '' })
		}

		onFixError(
			errors.filter(
				(error) =>
					![
						isSubjectToPiiaApprovalInput.name,
						hasBeenSubjectToApprovalInput.name,
						iaNumberInput.name
					].includes(error)
			)
		)
	}

	useUpdateInputs(isSubjectToPiiaApprovalInput)

	//------------------------------------------------------------

	const onSetHasBeenSubjectToApprovalInput = (value: string) => {
		setHasBeenSubjectToApprovalInput({
			...hasBeenSubjectToApprovalInput,
			value
		})

		if (value == radioListYesNo[0].value) {
			setIaNumberInput({ ...iaNumberInput, required: true })
		} else {
			setIaNumberInput({ ...iaNumberInput, required: false })
		}

		onFixError(
			errors.filter(
				(error) =>
					![hasBeenSubjectToApprovalInput.name, iaNumberInput.name].includes(
						error
					)
			)
		)
	}

	useUpdateInputs(hasBeenSubjectToApprovalInput)

	//------------------------------------------------------------

	//------------------------------------------------------------

	const onSetIaNumberInput = (value: string) => {
		handleInputChange(
			setIaNumberInput,
			iaNumberInput,
			value.toLocaleUpperCase(),
			iaNumberInput.name
		)
	}

	useUpdateInputs(iaNumberInput)

	//------------------------------------------------------------

	const onSetEcResolutionNumberInput = (value: string) => {
		handleInputChange(
			setEcResolutionNumberInput,
			ecResolutionNumberInput,
			value.toLocaleUpperCase(),
			ecResolutionNumberInput.name
		)
	}

	useUpdateInputs(ecResolutionNumberInput)

	//------------------------------------------------------------

	useEffect(() => {
		if (isMoving(whatToDoInput.value)) {
			updateMovingRequiredDocuments(mainUseOfExistingBuildingInput.value, true)
		} else {
			updateMovingRequiredDocuments('')
		}
	}, [isSubjectToPiiaApprovalInput, hasBeenSubjectToApprovalInput])

	//------------------------------------------------------------

	const getCadastralNumber = (coordinates: AddressCoordinates) => {
		getAddressByCoordinates(coordinates).then((res) => {
			setBuildingBatchNumberInput({
				...buildingBatchNumberInput,
				value: res?.data?.features?.[0].properties.cadastre
			})
		})
	}

	useEffect(() => {
		const coordinates: AddressCoordinates = new AddressCoordinates()
		coordinates.update(authUser?.profile?.address.coordinates!)

		if (coordinates.validate()) {
			setIsAddressOutsideLaval(false)
			getCadastralNumber(coordinates)
		} else {
			setIsAddressOutsideLaval(true)
		}
	}, [])

	// const isValidAddress = () => {
	// 	const coordinates: AddressCoordinates = new AddressCoordinates()
	// 	coordinates.update(authUser?.profile?.address.coordinates!)

	// 	return coordinates.validate()
	// }

	const onSetIsSameLocationInput = (value: string) => {
		setIsSameLocationInput({ ...isSameLocationInput, value })

		setDisableNextButton && setDisableNextButton(true)

		if (value == radioListYesNo[0].value) {
			if (isAddressOutsideLaval) {
				setDisableNextButton && setDisableNextButton(false)
			}
			setTimeout(() => {
				setShowAdresseBlock(false)
			}, 100)
		} else {
			setTimeout(() => {
				setShowAdresseBlock(true)
			}, 100)
		}

		onFixError(
			UpdateRequestInputsErrorsArray(errors!, isSameLocationInput.name)
		)
	}

	useUpdateInputs(isSameLocationInput)

	useEffect(() => {
		if (
			subSteps?.position === REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_2 &&
			isAddressOutsideLaval &&
			isSameLocationInput.value == radioListYesNo[0].value
		) {
			setDisableNextButton && setDisableNextButton(false)
		}
	}, [subSteps?.position])
	//------------------------------------------------------------

	const setRequiredOwnerInfoInputs = (value: boolean) => {
		setOwnerFirstNameInput({ ...ownerFirstNameInput, required: value })
		setOwnerLastNameInput({ ...ownerLastNameInput, required: value })
		setOwnerEmailInput({ ...ownerEmailInput, required: value })
		setOwnerPhoneNumberInput({ ...ownerPhoneNumberInput, required: value })
		setFirstQuestion({ ...firstQuestion, required: value })
	}

	const onSetIsLocationOwnedInput = (value: string) => {
		setIsLocationOwnedInput({ ...isLocationOwnedInput, value })

		if (value == radioListYesNo[1].value) {
			setRequiredOwnerInfoInputs(true)
		} else {
			setRequiredOwnerInfoInputs(false)
		}

		onFixError(
			errors.filter(
				(error) =>
					![
						isLocationOwnedInput.name,
						ownerFirstNameInput.name,
						ownerLastNameInput.name,
						ownerEmailInput.name,
						ownerPhoneNumberInput.name,
						firstQuestion.name
					].includes(error)
			)
		)
	}

	useUpdateInputs(isLocationOwnedInput)
	//------------------------------------------------------------

	const onSetOwnerFirstNameInput = (value: string) => {
		handleInputChange(
			setOwnerFirstNameInput,
			ownerFirstNameInput,
			value,
			ownerFirstNameInput.name
		)
	}

	useUpdateInputs(ownerFirstNameInput)
	//------------------------------------------------------------

	const onSetOwnerLastNameInput = (value: string) => {
		handleInputChange(
			setOwnerLastNameInput,
			ownerLastNameInput,
			value,
			ownerLastNameInput.name
		)
	}

	useUpdateInputs(ownerLastNameInput)

	//------------------------------------------------------------

	const onSetOwnerEmailInput = (value: string) => {
		handleInputChange(
			setOwnerEmailInput,
			ownerEmailInput,
			value,
			ownerEmailInput.name
		)
	}

	useUpdateInputs(ownerEmailInput)
	//------------------------------------------------------------

	const onSetOwnerPhoneNumberInput = (value: string) => {
		handleInputChange(
			setOwnerPhoneNumberInput,
			ownerPhoneNumberInput,
			value,
			ownerPhoneNumberInput.name
		)
	}

	useUpdateInputs(ownerPhoneNumberInput)
	//------------------------------------------------------------

	// ----------------------------------------------------------------
	// -------------------------  Substep 3 ---------------------------
	// ----------------------------------------------------------------

	const onSetMainUseOfExistingBuildingInput = (value: string) => {
		setMainUseOfExistingBuildingInput({
			...mainUseOfExistingBuildingInput,
			value
		})

		if (isMoving(whatToDoInput.value)) {
			updateMovingRequiredDocuments(value, true)
		} else {
			updateMovingRequiredDocuments('')
		}

		onFixError(
			errors.filter(
				(error) =>
					![
						mainUseOfExistingBuildingInput.name
						// Q10_1PAExistingMainBuildingHasHousingInput.name
					].includes(error)
			)
		)
	}

	useUpdateInputs(mainUseOfExistingBuildingInput)

	//------------------------------------------------------------

	const onSetStructureTypeInput = (value: string) => {
		handleInputChange(
			setStructureTypeInput,
			structureTypeInput,
			value,
			structureTypeInput.name
		)
	}

	useUpdateInputs(structureTypeInput)

	//------------------------------------------------------------

	const onSetIsTheNewBuildingInput = (value: string) => {
		handleInputChange(
			setIsTheNewBuildingInput,
			isTheNewBuildingInput,
			value,
			isTheNewBuildingInput.name
		)
	}

	useUpdateInputs(isTheNewBuildingInput)

	//------------------------------------------------------------

	const onSetIsTheNewBuildingPartOfAPropertyInput = (value: string) => {
		setIsTheNewBuildingPartOfAPropertyInput({
			...isTheNewBuildingPartOfAPropertyInput,
			value
		})

		if (value == radioListYesNo[0].value) {
			setSecondQuestion({ ...secondQuestion, required: true })
		} else {
			setSecondQuestion({ ...secondQuestion, required: false })
		}

		onFixError(
			errors.filter(
				(error) =>
					![
						isTheNewBuildingPartOfAPropertyInput.name,
						secondQuestion.name
					].includes(error)
			)
		)
	}

	useUpdateInputs(isTheNewBuildingPartOfAPropertyInput)

	//------------------------------------------------------------

	////////////////////////////////////////////////////////////////

	const onSetTypeOfBuildingToDemolishInput = (value: string) => {
		setTypeOfBuildingToDemolishInput({
			...typeOfBuildingToDemolishInput,
			value
		})

		// if (value == radioListTypeOfBuildingToDemolish[2].value) {
		// 	setTypeOfBuildingToDemolishPrecisionInput({
		// 		...typeOfBuildingToDemolishPrecisionInput,
		// 		required: true
		// 	})
		// } else {
		// 	setTypeOfBuildingToDemolishPrecisionInput({
		// 		...typeOfBuildingToDemolishPrecisionInput,
		// 		required: false,
		// 		value: ''
		// 	})
		// }

		onFixError(
			errors.filter(
				(error) =>
					![
						typeOfBuildingToDemolishInput.name,
						typeOfBuildingToDemolishPrecisionInput.name
					].includes(error)
			)
		)
	}

	useUpdateInputs(typeOfBuildingToDemolishInput)

	useEffect(() => {
		if (
			typeOfBuildingToDemolishInput.value ==
			radioListTypeOfBuildingToDemolish[2].value
		) {
			setTypeOfBuildingToDemolishPrecisionInput({
				...typeOfBuildingToDemolishPrecisionInput,
				required: true
			})
		} else {
			setTypeOfBuildingToDemolishPrecisionInput({
				...typeOfBuildingToDemolishPrecisionInput,
				required: false,
				value: ''
			})
		}
	}, [typeOfBuildingToDemolishInput])

	//------------------------------------------------------------

	const onSetTypeOfBuildingToDemolishPrecisionInput = (value: string) => {
		handleInputChange(
			setTypeOfBuildingToDemolishPrecisionInput,
			typeOfBuildingToDemolishPrecisionInput,
			value,
			typeOfBuildingToDemolishPrecisionInput.name
		)
	}

	useUpdateInputs(typeOfBuildingToDemolishPrecisionInput)

	//------------------------------------------------------------

	const onSetDemolitionTypeInput = (value: string) => {
		handleInputChange(
			setDemolitionTypeInput,
			demolitionTypeInput,
			value,
			demolitionTypeInput.name
		)
	}

	useUpdateInputs(demolitionTypeInput)

	//------------------------------------------------------------

	const onSetTypeOfBuildingToMoveInput = (value: string) => {
		setTypeOfBuildingToMoveInput({
			...typeOfBuildingToMoveInput,
			value
		})

		// if (value == radioListTypeOfBuildingToDemolish[2].value) {
		// 	setTypeOfBuildingToMovePrecisionInput({
		// 		...typeOfBuildingToMovePrecisionInput,
		// 		required: true
		// 	})
		// } else {
		// 	setTypeOfBuildingToMovePrecisionInput({
		// 		...typeOfBuildingToMovePrecisionInput,
		// 		required: false,
		// 		value: ''
		// 	})
		// }

		onFixError(
			errors.filter(
				(error) =>
					![
						typeOfBuildingToMoveInput.name,
						typeOfBuildingToMovePrecisionInput.name
					].includes(error)
			)
		)
	}

	useUpdateInputs(typeOfBuildingToMoveInput)

	useEffect(() => {
		if (
			typeOfBuildingToMoveInput.value ==
			radioListTypeOfBuildingToDemolish[2].value
		) {
			setTypeOfBuildingToMovePrecisionInput({
				...typeOfBuildingToMovePrecisionInput,
				required: true
			})
		} else {
			setTypeOfBuildingToMovePrecisionInput({
				...typeOfBuildingToMovePrecisionInput,
				required: false,
				value: ''
			})
		}
	}, [typeOfBuildingToMoveInput])

	//------------------------------------------------------------
	const onSetTypeOfBuildingToMovePrecisionInput = (value: string) => {
		handleInputChange(
			setTypeOfBuildingToMovePrecisionInput,
			typeOfBuildingToMovePrecisionInput,
			value,
			typeOfBuildingToMovePrecisionInput.name
		)
	}

	useUpdateInputs(typeOfBuildingToMovePrecisionInput)

	//------------------------------------------------------------
	const onSetIsTheBuildingToMoveInput = (value: string) => {
		handleInputChange(
			setIsTheBuildingToMoveInput,
			isTheBuildingToMoveInput,
			value,
			isTheBuildingToMoveInput.name
		)
	}

	useUpdateInputs(isTheBuildingToMoveInput)

	//------------------------------------------------------------

	const onSetIsLocationInLavalInput = (value: string) => {
		setIsLocationInLavalInput({
			...isLocationInLavalInput,
			value
		})

		// if (value == radioListYesNo[1].value) {
		// 	setTypeOfBuildingToMovePrecisionInput({
		// 		...typeOfBuildingToMovePrecisionInput,
		// 		required: true
		// 	})
		// } else {
		// 	setTypeOfBuildingToMovePrecisionInput({
		// 		...typeOfBuildingToMovePrecisionInput,
		// 		required: false,
		// 		value: ''
		// 	})
		// }

		onFixError(
			errors.filter(
				(error) =>
					![
						isLocationInLavalInput.name,
						isLocationInLavalPrecisionInput.name
					].includes(error)
			)
		)
	}

	useUpdateInputs(isLocationInLavalInput)

	useEffect(() => {
		if (isLocationInLavalInput.value == radioListYesNo[0].value) {
			setIsLocationInLavalPrecisionInput({
				...isLocationInLavalPrecisionInput,
				required: true
			})
		} else {
			setIsLocationInLavalPrecisionInput({
				...isLocationInLavalPrecisionInput,
				required: false,
				value: ''
			})
		}
	}, [isLocationInLavalInput])

	//------------------------------------------------------------
	const onSetIsLocationInLavalPrecisionInput = (value: string) => {
		handleInputChange(
			setIsLocationInLavalPrecisionInput,
			isLocationInLavalPrecisionInput,
			value,
			isLocationInLavalPrecisionInput.name
		)
	}

	useUpdateInputs(isLocationInLavalPrecisionInput)

	//------------------------------------------------------------
	const onSetReasonForMovingBuildingInput = (value: string) => {
		handleInputChange(
			setReasonForMovingBuildingInput,
			reasonForMovingBuildingInput,
			value,
			reasonForMovingBuildingInput.name
		)
	}

	useUpdateInputs(reasonForMovingBuildingInput)

	// ----------------------------------------------------------------
	// -------------------------  Substep 4 ---------------------------
	// ----------------------------------------------------------------

	//------------------------------------------------------------

	const onSetQ24IsWorkDueToDisasterInput = (value: string) => {
		setQ24IsWorkDueToDisasterInput({
			...Q24IsWorkDueToDisasterInput,
			value
		})

		if (value == radioListYesNo[0].value) {
			setQ24_1WorkDueToDisasterPrecisionInput({
				...Q24_1WorkDueToDisasterPrecisionInput,
				required: true
			})
		} else {
			setQ24_1WorkDueToDisasterPrecisionInput({
				...Q24_1WorkDueToDisasterPrecisionInput,
				required: false,
				value: ''
			})
		}

		onFixError(
			errors.filter(
				(error) =>
					![
						Q24IsWorkDueToDisasterInput.name,
						Q24_1WorkDueToDisasterPrecisionInput.name
					].includes(error)
			)
		)
	}

	useUpdateInputs(Q24IsWorkDueToDisasterInput)

	//------------------------------------------------------------

	const onSetQ24_1WorkDueToDisasterPrecisionInput = (value: string) => {
		handleInputChange(
			setQ24_1WorkDueToDisasterPrecisionInput,
			Q24_1WorkDueToDisasterPrecisionInput,
			value,
			Q24_1WorkDueToDisasterPrecisionInput.name
		)
	}

	useUpdateInputs(Q24_1WorkDueToDisasterPrecisionInput)

	//------------------------------------------------------------

	const onSetQ25ApproximateCostOfWorkInput = (value) => {
		// const rawValue = value?.toString().replace(/ /g, '')
		handleInputChange(
			setQ25ApproximateCostOfWorkInput,
			Q25ApproximateCostOfWorkInput,
			value,
			Q25ApproximateCostOfWorkInput.name
		)
	}

	useUpdateInputs(Q25ApproximateCostOfWorkInput)

	//------------------------------------------------------------

	//------------------------------------------------------------

	const onSetQ27IsTreeCuttingRequiredInput = (value: string) => {
		handleInputChange(
			setQ27IsTreeCuttingRequiredInput,
			Q27IsTreeCuttingRequiredInput,
			value,
			Q27IsTreeCuttingRequiredInput.name
		)
	}

	useUpdateInputs(Q27IsTreeCuttingRequiredInput)

	//------------------------------------------------------------

	const onSetQ28AdditionalInfoInput = (value: string) => {
		handleInputChange(
			setQ28AdditionalInfoInput,
			Q28AdditionalInfoInput,
			value,
			Q28AdditionalInfoInput.name
		)
	}

	useUpdateInputs(Q28AdditionalInfoInput)

	//------------------------------------------------------------

	const onSetQ29StartDateInput = (value: string) => {
		handleInputChange(
			setQ29StartDateInput,
			Q29StartDateInput,
			value,
			Q29StartDateInput.name
		)
	}

	useUpdateInputs(Q29StartDateInput)

	//------------------------------------------------------------

	const onSetQ30EndDateInput = (value: string) => {
		handleInputChange(
			setQ30EndDateInput,
			Q30EndDateInput,
			value,
			Q30EndDateInput.name
		)
	}

	useUpdateInputs(Q30EndDateInput)

	//------------------------------------------------------------

	const setCompanyInputsRequired = (value: boolean) => {
		setQ32CompanyNameExecutingWorkInput({
			...Q32CompanyNameExecutingWorkInput,
			required: value
		})
		setQ33CompanyNEQNumberInput({
			...Q33CompanyNEQNumberInput,
			required: value
		})
		setQ34CompanyRBQNumberInput({
			...Q34CompanyRBQNumberInput,
			required: value
		})
		setQ35CompanyRepresentativeNameInput({
			...Q35CompanyRepresentativeNameInput,
			required: value
		})
		setQ36CompanyRepresentativePhoneNumberInput({
			...Q36CompanyRepresentativePhoneNumberInput,
			required: value
		})
		setQ37CompanyRepresentativeEmailInput({
			...Q37CompanyRepresentativeEmailInput,
			required: value
		})
		setQ38CompanyAddressInput({
			...Q38CompanyAddressInput,
			required: value
		})
		setQ39CompanyCityInput({
			...Q39CompanyCityInput,
			required: value
		})
		setQ40CompanyPostalCodeInput({
			...Q40CompanyPostalCodeInput,
			required: value
		})
	}

	const onSetQ31WhoWillBeInChargeOfWorkInput = (value: string) => {
		setQ31WhoWillBeInChargeOfWorkInput({
			...Q31WhoWillBeInChargeOfWorkInput,
			value
		})

		setQ31_1EntrepreneurUnknownInput({
			...Q31_1EntrepreneurUnknownInput,
			value: 'false'
		})

		if (value == radioListWhoWillBeInChargeOfWork[0].value) {
			setCompanyInputsRequired(false)
		} else {
			setCompanyInputsRequired(true)
		}

		onFixError(
			errors.filter(
				(error) =>
					![
						Q31WhoWillBeInChargeOfWorkInput.name,
						Q32CompanyNameExecutingWorkInput.name,
						Q33CompanyNEQNumberInput.name,
						Q34CompanyRBQNumberInput.name,
						Q35CompanyRepresentativeNameInput.name,
						Q36CompanyRepresentativePhoneNumberInput.name,
						Q37CompanyRepresentativeEmailInput.name,
						Q38CompanyAddressInput.name,
						Q39CompanyCityInput.name,
						Q40CompanyPostalCodeInput.name
					].includes(error)
			)
		)
	}

	useUpdateInputs(Q31WhoWillBeInChargeOfWorkInput)

	// --------------------------------------------------------------------------

	const onSetQ31_1EntrepreneurUnknownInput = (value: string) => {
		setQ31_1EntrepreneurUnknownInput({
			...Q31_1EntrepreneurUnknownInput,
			value
		})

		if (value?.toString() == 'true') {
			setCompanyInputsRequired(false)
		} else {
			setCompanyInputsRequired(true)
		}

		onFixError(
			errors.filter(
				(error) =>
					![
						Q32CompanyNameExecutingWorkInput.name,
						Q33CompanyNEQNumberInput.name,
						Q34CompanyRBQNumberInput.name,
						Q35CompanyRepresentativeNameInput.name,
						Q36CompanyRepresentativePhoneNumberInput.name,
						Q37CompanyRepresentativeEmailInput.name,
						Q38CompanyAddressInput.name,
						Q39CompanyCityInput.name,
						Q40CompanyPostalCodeInput.name
					].includes(error)
			)
		)
	}

	useUpdateInputs(Q31_1EntrepreneurUnknownInput)

	//------------------------------------------------------------

	const onSetQ32CompanyNameExecutingWorkInput = (value: string) => {
		handleInputChange(
			setQ32CompanyNameExecutingWorkInput,
			Q32CompanyNameExecutingWorkInput,
			value,
			Q32CompanyNameExecutingWorkInput.name
		)
	}

	useUpdateInputs(Q32CompanyNameExecutingWorkInput)

	//------------------------------------------------------------

	const onSetQ33CompanyNEQNumberInput = (value: string) => {
		handleInputChange(
			setQ33CompanyNEQNumberInput,
			Q33CompanyNEQNumberInput,
			value,
			Q33CompanyNEQNumberInput.name
		)
	}

	useUpdateInputs(Q33CompanyNEQNumberInput)
	//------------------------------------------------------------

	const onSetQ34CompanyRBQNumberInput = (value: string) => {
		handleInputChange(
			setQ34CompanyRBQNumberInput,
			Q34CompanyRBQNumberInput,
			value,
			Q34CompanyRBQNumberInput.name
		)
	}

	useUpdateInputs(Q34CompanyRBQNumberInput)
	//------------------------------------------------------------

	const onSetQ35CompanyRepresentativeNameInput = (value: string) => {
		handleInputChange(
			setQ35CompanyRepresentativeNameInput,
			Q35CompanyRepresentativeNameInput,
			value,
			Q35CompanyRepresentativeNameInput.name
		)
	}

	useUpdateInputs(Q35CompanyRepresentativeNameInput)
	//------------------------------------------------------------
	const onSetQ36CompanyRepresentativePhoneNumberInput = (value: string) => {
		handleInputChange(
			setQ36CompanyRepresentativePhoneNumberInput,
			Q36CompanyRepresentativePhoneNumberInput,
			value,
			Q36CompanyRepresentativePhoneNumberInput.name
		)
	}

	useUpdateInputs(Q36CompanyRepresentativePhoneNumberInput)
	//------------------------------------------------------------

	const onSetQ37CompanyRepresentativeEmailInput = (value: string) => {
		handleInputChange(
			setQ37CompanyRepresentativeEmailInput,
			Q37CompanyRepresentativeEmailInput,
			value,
			Q37CompanyRepresentativeEmailInput.name
		)
	}

	useUpdateInputs(Q37CompanyRepresentativeEmailInput)
	//------------------------------------------------------------

	const onSetQ38CompanyAddressInput = (value: string) => {
		handleInputChange(
			setQ38CompanyAddressInput,
			Q38CompanyAddressInput,
			value,
			Q38CompanyAddressInput.name
		)
	}

	useUpdateInputs(Q38CompanyAddressInput)
	//------------------------------------------------------------

	const onSetQ39CompanyCityInput = (value: string) => {
		handleInputChange(
			setQ39CompanyCityInput,
			Q39CompanyCityInput,
			value,
			Q39CompanyCityInput.name
		)
	}

	useUpdateInputs(Q39CompanyCityInput)
	//------------------------------------------------------------
	const onSetQ40CompanyPostalCodeInput = (value: string) => {
		handleInputChange(
			setQ40CompanyPostalCodeInput,
			Q40CompanyPostalCodeInput,
			value?.toUpperCase(),
			Q40CompanyPostalCodeInput.name
		)
	}

	useUpdateInputs(Q40CompanyPostalCodeInput)
	//------------------------------------------------------------

	const onSetQ41IsWaterAndSewerConnectionRequiredInput = (value: string) => {
		setQ41IsWaterAndSewerConnectionRequiredInput({
			...Q41IsWaterAndSewerConnectionRequiredInput,
			value: value
		})

		if (value == radioListYesNo[1].value) {
			setQ41IsPropertyConnectedToMunicipalConduitsInput({
				...Q41IsPropertyConnectedToMunicipalConduitsInput,
				value: ''
			})
			setQ43PlannedWorkInput({
				...Q43PlannedWorkInput,
				value: ''
			})
			setQ44AdditionalInfoInput({
				...Q44AdditionalInfoInput,
				required: false
			})

			setDisabledMunicipalConduits([])
		}

		onFixError(
			errors.filter(
				(error) =>
					![
						Q41IsWaterAndSewerConnectionRequiredInput.name,
						Q42AdditionalInfoInput.name,
						Q44AdditionalInfoInput.name
					].includes(error)
			)
		)
	}

	useUpdateInputs(Q41IsWaterAndSewerConnectionRequiredInput)

	//------------------------------------------------------------

	const [disabledMunicipalConduits, setDisabledMunicipalConduits] = useState<
		string[]
	>([])

	const onSetQ41IsPropertyConnectedToMunicipalConduitsInput = (
		value: string
	) => {
		let newValue
		if (Q41IsPropertyConnectedToMunicipalConduitsInput.value?.includes(value)) {
			newValue = pageUtils.removeStrFromStrArray(
				value,
				Q41IsPropertyConnectedToMunicipalConduitsInput.value
			)
		} else {
			newValue =
				Q41IsPropertyConnectedToMunicipalConduitsInput.value == ''
					? value
					: Q41IsPropertyConnectedToMunicipalConduitsInput.value + `, ${value}`
		}

		setQ41IsPropertyConnectedToMunicipalConduitsInput({
			...Q41IsPropertyConnectedToMunicipalConduitsInput,
			value: newValue
		})

		// Disable the value of the radio button that is not selected
		if (
			radioListIsPropertyConnectedToMunicipalConduits.slice(-1)[0].value !==
			value
		) {
			setDisabledMunicipalConduits([
				radioListIsPropertyConnectedToMunicipalConduits.slice(-1)[0].value
			])
		}
		// Disable the value of the radio button that is selected
		if (
			radioListIsPropertyConnectedToMunicipalConduits.slice(-1)[0].value ==
			value
		) {
			setDisabledMunicipalConduits(
				radioListIsPropertyConnectedToMunicipalConduits
					.slice(0, 4)
					.map((item) => item.value)
			)
		}
		// Enable the value of the radio button if the value is empty
		if (newValue == '') {
			setDisabledMunicipalConduits([])
		}

		onFixError(
			UpdateRequestInputsErrorsArray(
				errors!,
				Q41IsPropertyConnectedToMunicipalConduitsInput.name
			)
		)
	}

	useUpdateInputs(Q41IsPropertyConnectedToMunicipalConduitsInput)

	//-------------------------------------------------------------

	const onSetQ42AdditionalInfoInput = (value: string) => {
		handleInputChange(
			setQ42AdditionalInfoInput,
			Q42AdditionalInfoInput,
			value,
			Q42AdditionalInfoInput.name
		)
	}

	useUpdateInputs(Q42AdditionalInfoInput)

	//------------------------------------------------------------

	const onSetQ43PlannedWorkInput = (value: string) => {
		let newValue
		if (Q43PlannedWorkInput.value?.includes(value)) {
			newValue = pageUtils.removeStrFromStrArray(
				value,
				Q43PlannedWorkInput.value
			)
		} else {
			newValue =
				Q43PlannedWorkInput.value == ''
					? value
					: Q43PlannedWorkInput.value + `, ${value}`
		}

		setQ43PlannedWorkInput({
			...Q43PlannedWorkInput,
			value: newValue
		})

		if (newValue.includes(listPlannedWork.slice(-1)[0].value)) {
			setQ44AdditionalInfoInput({
				...Q44AdditionalInfoInput,
				required: true
			})
		} else {
			setQ44AdditionalInfoInput({
				...Q44AdditionalInfoInput,
				required: false
			})
		}

		onFixError(
			errors.filter(
				(error) =>
					![Q43PlannedWorkInput.name, Q44AdditionalInfoInput.name].includes(
						error
					)
			)
		)
	}

	useUpdateInputs(Q43PlannedWorkInput)
	// --------------------------------------------------------------------------

	const onSetQ44AdditionalInfoInput = (value: string) => {
		handleInputChange(
			setQ44AdditionalInfoInput,
			Q44AdditionalInfoInput,
			value,
			Q44AdditionalInfoInput.name
		)
	}

	useUpdateInputs(Q44AdditionalInfoInput)

	// --------------------------------------------------------------------------

	// --------------------------------------------------------------------------
	// --------------------------------------------------------------------------

	//------------------------------------------------------------

	// --------------------------------------------------------------------------

	//////////////////////////////////////////////////////////////////////////////////////////////
	//////////////////////////////////////////////////////////////////////////////////////////////

	useEffect(() => {
		onSetInputs(UpdateRequestInputsArray(inputs!, dummyOptionalInput))
	}, [dummyOptionalInput])

	////////////////////////////////////////////////////////////////////////////////////////////////
	////////////////////////////////////////////////////////////////////////////////////////////////
	// DOCUMENTS
	////////////////////////////////////////////////////////////////////////////////////////////////
	////////////////////////////////////////////////////////////////////////////////////////////////

	const updateQuestionFiles = (setQuestion, question, files) => {
		setQuestion({
			...question,
			files
		})
		onFixError(UpdateRequestInputsErrorsArray(errors, question.name))
	}

	const onSelectFileFirstQuestion = (files: Set<File>) => {
		updateQuestionFiles(setFirstQuestion, firstQuestion, files)
	}

	const onSelectFileSecondQuestion = (files: Set<File>) => {
		updateQuestionFiles(setSecondQuestion, secondQuestion, files)
	}

	const onSelectFileThirdQuestion = (files: Set<File>) => {
		updateQuestionFiles(setThirdQuestion, thirdQuestion, files)
	}

	const onSelectFileFourthQuestion = (files: Set<File>) => {
		updateQuestionFiles(setFourthQuestion, fourthQuestion, files)
	}

	const onSelectFileFifthQuestion = (files: Set<File>) => {
		updateQuestionFiles(setFifthQuestion, fifthQuestion, files)
	}

	const onSelectFileSixthQuestion = (files: Set<File>) => {
		updateQuestionFiles(setSixthQuestion, sixthQuestion, files)
	}

	const onSelectFileSeventhQuestion = (files: Set<File>) => {
		updateQuestionFiles(setSeventhQuestion, seventhQuestion, files)
	}

	const onSelectFileEighthQuestion = (files: Set<File>) => {
		updateQuestionFiles(setEighthQuestion, eighthQuestion, files)
	}

	const onSelectFileNinthQuestion = (files: Set<File>) => {
		updateQuestionFiles(setNinthQuestion, ninthQuestion, files)
	}

	const onSelectFileTenthQuestion = (files: Set<File>) => {
		updateQuestionFiles(setTenthQuestion, tenthQuestion, files)
	}

	const onSelectFileEleventhQuestion = (files: Set<File>) => {
		updateQuestionFiles(setEleventhQuestion, eleventhQuestion, files)
	}

	////////////////////////////////////////////////////////////////////////////////////////////////
	////////////////////////////////////////////////////////////////////////////////////////////////
	// FILES
	////////////////////////////////////////////////////////////////////////////////////////////////

	////////////////////////////////////////////////////////////////////////////////////////////////
	useEffect(() => {
		onSetInputFiles(UpdateRequestInputFilesArray(inputFiles, firstQuestion))
	}, [firstQuestion])

	useEffect(() => {
		onSetInputFiles(UpdateRequestInputFilesArray(inputFiles, secondQuestion))
	}, [secondQuestion])

	useEffect(() => {
		onSetInputFiles(UpdateRequestInputFilesArray(inputFiles, thirdQuestion))
	}, [thirdQuestion])

	useEffect(() => {
		onSetInputFiles(UpdateRequestInputFilesArray(inputFiles, fourthQuestion))
	}, [fourthQuestion])

	useEffect(() => {
		onSetInputFiles(UpdateRequestInputFilesArray(inputFiles, fifthQuestion))
	}, [fifthQuestion])

	useEffect(() => {
		onSetInputFiles(UpdateRequestInputFilesArray(inputFiles, sixthQuestion))
	}, [sixthQuestion])

	useEffect(() => {
		onSetInputFiles(UpdateRequestInputFilesArray(inputFiles, seventhQuestion))
	}, [seventhQuestion])

	useEffect(() => {
		onSetInputFiles(UpdateRequestInputFilesArray(inputFiles, eighthQuestion))
	}, [eighthQuestion])

	useEffect(() => {
		onSetInputFiles(UpdateRequestInputFilesArray(inputFiles, ninthQuestion))
	}, [ninthQuestion])

	useEffect(() => {
		onSetInputFiles(UpdateRequestInputFilesArray(inputFiles, tenthQuestion))
	}, [tenthQuestion])

	useEffect(() => {
		onSetInputFiles(UpdateRequestInputFilesArray(inputFiles, eleventhQuestion))
	}, [eleventhQuestion])

	////////////////////////////////////////////////////////////////////////////////////////////////
	// FILES FROM STORE

	// Helper function to set question files
	const setQuestionFiles = (inputName: string, question, setQuestion) => {
		if (!!answers[inputName] && question.files.size == 0) {
			getFilesFromStore(inputName, answers).then((result: Set<File>) => {
				setQuestion({
					...question,
					files: result
				})
			})
		}
	}

	useEffect(() => {
		// check if exist answers (filesInputs) at the store, to intialize the input with the files already present
		// ----------------------------
		// firstInput input
		setQuestionFiles('firstInput', firstQuestion, setFirstQuestion)

		// secondInput input
		setQuestionFiles('secondInput', secondQuestion, setSecondQuestion)

		// thirdInput input
		setQuestionFiles('thirdInput', thirdQuestion, setThirdQuestion)

		// fouthInput input
		setQuestionFiles('fourthInput', fourthQuestion, setFourthQuestion)

		// fifthInput input
		setQuestionFiles('fifthInput', fifthQuestion, setFifthQuestion)

		// sixthQuestion input
		setQuestionFiles('sixthInput', sixthQuestion, setSixthQuestion)

		// seventhQuestion input
		setQuestionFiles('seventhInput', seventhQuestion, setSeventhQuestion)

		// eighthQuestion input
		setQuestionFiles('eighthInput', eighthQuestion, setEighthQuestion)

		// ninthQuestion input
		setQuestionFiles('ninthInput', ninthQuestion, setNinthQuestion)

		// tenthQuestion input
		setQuestionFiles('tenthInput', tenthQuestion, setTenthQuestion)

		// eleventhQuestion input
		setQuestionFiles('eleventhInput', eleventhQuestion, setEleventhQuestion)
	}, [])

	const onSetErrorFromFileComponent = (
		hasErrorInComponent: boolean,
		inputName: string
	) => {
		if (hasErrorInComponent) {
			if (!errors.includes(inputName)) {
				onFixError((oldErrors) => [...oldErrors, inputName])
			}

			return
		}

		onFixError(UpdateRequestInputsErrorsArray(errors, inputName))
	}

	////////////////////////////////////////////////////////////////////////////////////////////////
	const REQUEST_STEPS = (value) => {
		return [
			{
				title: formatStrapiText(pageAssets?.page_requestInfo_step1),
				subSteps: []
			},
			{
				title: formatStrapiText(pageAssets?.page_requestInfo_step2),
				subSteps: []
			},
			{
				title: formatStrapiText(pageAssets?.page_requestInfo_step3),
				subSteps: [
					pageAssets?.request_form_type_de_demandes,
					pageAssets?.request_form_request_location,
					pageAssets?.request_form_type_of_use,
					value,
					pageAssets?.request_form_information_on_the_work,
					pageAssets?.request_form_branching,
					pageAssets?.request_form_required_documents
				]
			},
			// {
			// 	title: formatStrapiText(
			// 		pageAssets?.page_requestInfo_step_complementary_requests
			// 	),
			// 	subSteps: [pageAssets?.page_requestInfo_step_complementary_requests]
			// },
			{
				title: formatStrapiText(pageAssets?.page_requestInfo_step4),
				subSteps: []
			},
			{
				title: formatStrapiText(pageAssets?.page_requestInfo_step5),
				subSteps: []
			}
		]
	}

	useEffect(() => {
		// setSteps &&
		// 	setSteps({
		// 		...StepsUrbanism
		// 	})

		onSetSubSteps({
			map: false,
			details_1: true,
			details_2: true,
			details_3: true,
			details_4: true,
			details_5: true,
			details_6: true,
			requiredDocument: true,
			position:
				subSteps?.position && String(subSteps?.position) !== ''
					? subSteps?.position
					: REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_1,
			steps: [
				REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_1,
				REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_2,
				REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_3,
				REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_4,
				REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_5,
				REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_6,
				REQUEST_TN_DETAILS_STEPS_NAME.DOCUMENTS
			]
		})

		setStepsList &&
			setStepsList(
				REQUEST_STEPS(
					answers?.whatToDo
						? isDemolition(answers?.whatToDo)
							? pageAssets?.request_form_building_demolition
							: pageAssets?.request_form_building_moving
						: pageAssets?.request_form_information
				)
			)
	}, [])

	const backToForm = (
		step: number,
		subStep: string,
		currentSubStep: number
	) => {
		if (setCurrentStep && onSetSubSteps && subSteps && onSetCurrentSubStep) {
			setCurrentStep(step)
			onSetSubSteps({
				...subSteps,
				position: subStep as REQUEST_TN_DETAILS_STEPS_NAME
			})
			onSetCurrentSubStep(currentSubStep)
		}
	}

	const getLabelFromList = (list, value) => {
		return list?.find((element) => element.value == value?.toString())?.label
	}

	const questionAnswer = (question: string, answer?: string) => {
		if (answer) {
			return (
				<div>
					<strong>{question}</strong>
					<br />
					<span className={pageUtils.classes.answerURB}>{answer}</span>
				</div>
			)
		}
	}

	const getLabelsFromString = (list, answer?: string): string[] => {
		return list
			.filter((element) => answer?.includes(element.value))
			.map((item) => item.label)
	}

	// a function to render RequestFiles on the DOCUMENT or Summary step
	const renderRequestFiles = (
		type: string,
		category: string | undefined,
		question: RequestInputFiles,
		onSelectFileFunction,
		status: string,
		isSummary?: boolean
	) => {
		if (isSummary == true) {
			return (
				isDocumentToShow(type, category, question.name, status) &&
				question.files.size > 0 && (
					<RequestFiles
						subtitle={question.label}
						name={question.name}
						required={question.required}
						files={question.files}
						hasError={errors.includes(question.name)}
						removeSectionMarginTop
						onSetHasError={onSetErrorFromFileComponent}
						onSetFiles={onSelectFileFunction}
						maxFiles={question?.numberMaxFiles}
						isSummary
					/>
				)
			)
		}

		return (
			isDocumentToShow(type, category, question.name, status, true) && (
				<RequestFiles
					subtitle={question.label}
					name={question.name}
					required={question.required}
					files={question.files}
					hasError={errors.includes(question.name)}
					removeSectionMarginTop
					onSetHasError={onSetErrorFromFileComponent}
					onSetFiles={onSelectFileFunction}
					subSteps={subSteps}
					maxFiles={question?.numberMaxFiles}
					description={question.description}
				/>
			)
		)
	}

	// show the files on the DOCUMENT step
	// @status : the status of the document (required or optionnal)
	// @isSummary : if the step is the summary step
	const renderMovingRequestFiles = (status: string, isSummary: boolean) => {
		return (
			<>
				{renderRequestFiles(
					'MOVING',
					mainUseOfExistingBuildingInput.value,
					fifthQuestion,
					onSelectFileFifthQuestion,
					status,
					isSummary
				)}
				{renderRequestFiles(
					'MOVING',
					mainUseOfExistingBuildingInput.value,
					sixthQuestion,
					onSelectFileSixthQuestion,
					status,
					isSummary
				)}
				{renderRequestFiles(
					'MOVING',
					mainUseOfExistingBuildingInput.value,
					seventhQuestion,
					onSelectFileSeventhQuestion,
					status,
					isSummary
				)}
				{renderRequestFiles(
					'MOVING',
					mainUseOfExistingBuildingInput.value,
					eighthQuestion,
					onSelectFileEighthQuestion,
					status,
					isSummary
				)}
				{renderRequestFiles(
					'MOVING',
					mainUseOfExistingBuildingInput.value,
					ninthQuestion,
					onSelectFileNinthQuestion,
					status,
					isSummary
				)}
				{renderRequestFiles(
					'MOVING',
					mainUseOfExistingBuildingInput.value,
					tenthQuestion,
					onSelectFileTenthQuestion,
					status,
					isSummary
				)}
				{renderRequestFiles(
					'MOVING',
					mainUseOfExistingBuildingInput.value,
					eleventhQuestion,
					onSelectFileEleventhQuestion,
					status,
					isSummary
				)}
			</>
		)
	}

	useEffect(() => {
		{
			console.log('Errors -> ', errors)
			// console.table(inputFiles)
		}
	}, [errors])

	const isAucunChoix = () => {
		if (answers?.existingMainBuildingIs == radioListIsTheNewBuilding[2].value) {
			return `(${radioListIsTheNewBuilding[0].label}, ${radioListIsTheNewBuilding[1].label})`
		}
		return ''
	}

	if (isSummaryStep) {
		return (
			<section
				className={joinClasses([
					pageUtils.classes.noMargin,
					pageUtils.classes.collapse,
					pageUtils.classes.summarySubTitles,
					toPrint ? pageUtils.classes.toPrintTitle : ''
				])}
				style={{ padding: '0px' }}
			>
				{subSteps?.details_1 && (
					<>
						<Collapse
							id={1}
							buttonText={pageAssets?.request_form_type_de_demandes}
							isOpen={toPrint}
							key={`request_form_type_de_demandes`}
						>
							<div style={{ position: 'relative' }}>
								{!toPrint && (
									<a
										style={{
											display: toPrint ? 'none' : 'flex'
										}}
										className={joinClasses([
											pageUtils.classes.btn,
											pageUtils.classes.button,
											pageUtils.classes.buttonOutline,
											pageUtils.classes.summaryCollapseButton
										])}
										onClick={() =>
											backToForm(
												steps?.form!,
												REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_1,
												1
											)
										}
									>
										{pageAssets?.label_modify}
									</a>
								)}
								{toPrint && (
									<h4 className={pageUtils.classes.h4}>
										{pageAssets?.request_form_type_de_demandes}
									</h4>
								)}
							</div>

							{/* Question 1 */}
							<div>
								<strong>{whatToDoInput.label}</strong>
								<br />
								<span className={pageUtils.classes.answerURB}>
									<Text
										content={getLabelFromList(
											radioListWhatToDo,
											answers?.whatToDo
										)}
									/>
								</span>
							</div>

							{/* Question 2 */}
							<div>
								<strong>{requestAsInput.label}</strong>
								<br />
								<span className={pageUtils.classes.answerURB}>
									{getLabelFromList(radioListRequestAs, answers?.requestAs)}
								</span>
							</div>

							{[
								REQUEST_CONFIGS_KEYS.requestAs.entreprise,
								REQUEST_CONFIGS_KEYS.requestAs.entrepreneur_or_manager
							].includes(answers?.requestAs?.toString() || '') && (
								<>
									<div>
										<strong>{companyNameInput.label}</strong>
										<br />
										<span className={pageUtils.classes.answerURB}>
											{answers?.companyName}
										</span>
									</div>
									<div>
										<strong>{representativeNameInput.label}</strong>
										<br />
										<span className={pageUtils.classes.answerURB}>
											{answers?.representativeName}
										</span>
									</div>
								</>
							)}

							{isMoving(answers?.whatToDo) && (
								<>
									{/* Question 3: Is Subject to PIIA Approval */}
									<div>
										<strong>{isSubjectToPiiaApprovalInput.label}</strong>
										<br />
										<span className={pageUtils.classes.answerURB}>
											{getLabelFromList(
												radioListYesNo,
												answers?.isSubjectToPiiaApproval
											)}
										</span>
									</div>

									{/* Question 4: Has Been Subject to Approval */}
									{answers?.isSubjectToPiiaApproval?.toString() ==
										radioListYesNo[0].value && (
										<>
											<div>
												<strong>{hasBeenSubjectToApprovalInput.label}</strong>
												<br />
												<span className={pageUtils.classes.answerURB}>
													{getLabelFromList(
														radioListYesNo,
														answers?.hasBeenSubjectToApproval
													)}
												</span>
											</div>

											{answers?.hasBeenSubjectToApproval?.toString() ==
												radioListYesNo[0].value && (
												<>
													{/* Question 4.1: IA Number */}
													<div>
														<strong>{iaNumberInput.label}</strong>
														<br />
														<span className={pageUtils.classes.answerURB}>
															{answers?.iaNumber}
														</span>
													</div>

													{/* Question 4.2: EC Resolution Number */}
													{answers?.ecResolutionNumber && (
														<div>
															<strong>{ecResolutionNumberInput.label}</strong>
															<br />
															<span className={pageUtils.classes.answerURB}>
																{answers?.ecResolutionNumber}
															</span>
														</div>
													)}
												</>
											)}
										</>
									)}
								</>
							)}
						</Collapse>
					</>
				)}

				{subSteps?.details_2 && (
					<>
						<Collapse
							id={2}
							buttonText={pageAssets?.request_form_request_location}
							isOpen={toPrint}
							key={`request_form_request_location`}
						>
							<div style={{ position: 'relative' }}>
								{!toPrint && (
									<a
										style={{
											display: toPrint ? 'none' : 'flex'
										}}
										className={joinClasses([
											pageUtils.classes.btn,
											pageUtils.classes.button,
											pageUtils.classes.buttonOutline,
											pageUtils.classes.summaryCollapseButton
										])}
										onClick={() =>
											backToForm(
												steps?.form!,
												REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_2,
												2
											)
										}
									>
										{pageAssets?.label_modify}
									</a>
								)}
								{toPrint && (
									<h4 className={pageUtils.classes.h4}>
										{pageAssets?.request_form_request_location}
									</h4>
								)}
							</div>

							{/* Question 7 */}

							<div>
								<strong>{isSameLocationInput.label}</strong>
								<br />
								<span className={pageUtils.classes.answerURB}>
									{getLabelFromList(
										radioListYesNo,
										answers?.buildingAddressSameHasApplicant
									)}
								</span>
							</div>

							{/* Question 8 */}
							{answers?.buildingAddressSameHasApplicant?.toString() ==
								radioListYesNo[1].value && (
								<>
									<div>
										<strong>{pageAssets?.request_form_location}</strong>
										<br />
										<span className={pageUtils.classes.answerURB}>
											{answers?.addressField ? (
												<>
													{answers?.addressField}
													<br />
												</>
											) : (
												''
											)}

											{answers?.addressLotNumber
												? pageAssets?.label_cadastral_number +
												  ': ' +
												  answers?.addressLotNumber
												: ''}
										</span>
									</div>
									{(answers?.addressVoiePublic ||
										answers?.addressCivicNumber) && (
										<div>
											<strong>
												{
													pageAssets?.request_form_addressVoiePublicSection_public_road
												}
											</strong>
											<br />
											<span>
												{answers?.addressCivicNumber}
												{answers?.addressCivicNumber &&
													answers?.addressVoiePublic &&
													', '}
												{answers?.addressVoiePublic}
											</span>
										</div>
									)}
									{(answers?.addressVoiePublic2 ||
										answers?.addressCivicNumber2) && (
										<div>
											<span>
												{answers?.addressCivicNumber2}
												{answers?.addressCivicNumber2 &&
													answers?.addressVoiePublic2 &&
													', '}
												{answers?.addressVoiePublic2}
											</span>
										</div>
									)}

									{(answers?.addressVoiePublic3 ||
										answers?.addressCivicNumber3) && (
										<div>
											<span>
												{answers?.addressCivicNumber3}
												{answers?.addressCivicNumber3 &&
													answers?.addressVoiePublic3 &&
													', '}
												{answers?.addressVoiePublic3}
											</span>
										</div>
									)}

									{answers?.addressAdditionalInfos && (
										<div>
											<span>{answers?.addressAdditionalInfos}</span>
										</div>
									)}
									<br />
								</>
							)}

							{/* Question 9 */}
							<div>
								<strong>{isLocationOwnedInput.label}</strong>
								<br />
								<span className={pageUtils.classes.answerURB}>
									{getLabelFromList(radioListYesNo, answers?.isLocationOwned)}
								</span>
							</div>

							{/* ****** Owner inputs ****** */}
							{answers?.isLocationOwned?.toString() ==
								radioListYesNo[1].value && (
								<>
									{questionAnswer(
										ownerFirstNameInput.label,
										answers?.ownerFirstName
									)}
									{questionAnswer(
										ownerLastNameInput.label,
										answers?.ownerLastName
									)}
									{questionAnswer(ownerEmailInput.label, answers?.ownerEmail)}
									{questionAnswer(
										ownerPhoneNumberInput.label,
										answers?.ownerPhoneNumber
									)}

									<RequestFiles
										subtitle={firstQuestion.label}
										name={firstQuestion.name}
										required={firstQuestion.required}
										files={firstQuestion.files}
										hasError={errors.includes(firstQuestion.name)}
										removeSectionMarginTop
										removePadding
										equivalentMargin
										onSetHasError={onSetErrorFromFileComponent}
										onSetFiles={onSelectFileFirstQuestion}
										maxFiles={firstQuestion.numberFilesRequired}
										isSummary
									>
										<></>
									</RequestFiles>
									<br />
								</>
							)}
						</Collapse>
					</>
				)}

				{subSteps?.details_3 && (
					<>
						<Collapse
							id={3}
							buttonText={pageAssets?.request_form_type_of_use}
							isOpen={toPrint}
							key={`request_form_type_of_use`}
						>
							<div style={{ position: 'relative' }}>
								{!toPrint && (
									<a
										style={{
											display: toPrint ? 'none' : 'flex'
										}}
										className={joinClasses([
											pageUtils.classes.btn,
											pageUtils.classes.button,
											pageUtils.classes.buttonOutline,
											pageUtils.classes.summaryCollapseButton
										])}
										onClick={() =>
											backToForm(
												steps?.form!,
												REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_3,
												3
											)
										}
									>
										{pageAssets?.label_modify}
									</a>
								)}
								{toPrint && (
									<h4 className={pageUtils.classes.h4}>
										{pageAssets?.request_form_type_of_use}
									</h4>
								)}
							</div>

							<div>
								<>
									{/* Question 10 */}
									{questionAnswer(
										mainUseOfExistingBuildingInput.label,
										getLabelFromList(
											radioListMainUseOfExistingBuilding,
											answers?.mainUseOfExistingBuilding
										)
									)}

									{/* Question 13 */}
									{questionAnswer(
										structureTypeInput.label,
										getLabelFromList(
											radioListStructureType,
											answers?.existingMainBuildingStructureType
										)
									)}

									{/* Question 11 */}
									{questionAnswer(
										`${
											pageAssets?.request_form_buildingDemolitionOrMovingPermit_isTheNewBuilding
										} ${isAucunChoix()}`,
										getLabelFromList(
											radioListIsTheNewBuilding,
											answers?.existingMainBuildingIs
										)
									)}
									{/* Question 12 */}
									{questionAnswer(
										isTheNewBuildingPartOfAPropertyInput.label,
										getLabelFromList(
											radioListYesNo,
											answers?.existingMainBuildingIsShared
										)
									)}

									{answers?.existingMainBuildingIsShared?.toString() ==
										radioListYesNo[0].value && (
										<RequestFiles
											subtitle={secondQuestion.label}
											name={secondQuestion.name}
											required={secondQuestion.required}
											files={secondQuestion.files}
											hasError={errors.includes(secondQuestion.name)}
											removeSectionMarginTop
											removePadding
											equivalentMargin
											onSetHasError={onSetErrorFromFileComponent}
											onSetFiles={onSelectFileSecondQuestion}
											maxFiles={secondQuestion.numberFilesRequired}
											isSummary
										>
											<></>
										</RequestFiles>
									)}
								</>
							</div>
						</Collapse>
					</>
				)}
				{subSteps?.details_4 && (
					<>
						<Collapse
							id={4}
							buttonText={
								isDemolition(answers?.whatToDo)
									? pageAssets?.request_form_building_demolition
									: pageAssets?.request_form_building_moving
							}
							isOpen={toPrint}
							key={`request_form_building_informations`}
						>
							<div style={{ position: 'relative' }}>
								{!toPrint && (
									<a
										style={{
											display: toPrint ? 'none' : 'flex'
										}}
										className={joinClasses([
											pageUtils.classes.btn,
											pageUtils.classes.button,
											pageUtils.classes.buttonOutline,
											pageUtils.classes.summaryCollapseButton
										])}
										onClick={() =>
											backToForm(
												steps?.form!,
												REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_4,
												4
											)
										}
									>
										{pageAssets?.label_modify}
									</a>
								)}
								{toPrint && (
									<h4 className={pageUtils.classes.h4}>
										{isDemolition(answers?.whatToDo)
											? pageAssets?.request_form_building_demolition
											: pageAssets?.request_form_building_moving}
									</h4>
								)}
							</div>

							{isDemolition(answers?.whatToDo) && (
								<>
									{questionAnswer(
										typeOfBuildingToDemolishInput.label,
										getLabelFromList(
											radioListTypeOfBuildingToDemolish,
											answers?.typeOfBuildingToDemolish
										)
									)}

									{answers?.typeOfBuildingToDemolish?.toString() ==
										radioListTypeOfBuildingToDemolish[2].value &&
										questionAnswer(
											typeOfBuildingToDemolishPrecisionInput.label,
											answers?.typeOfBuildingToDemolishPrecision
										)}

									{questionAnswer(
										demolitionTypeInput.label,
										getLabelFromList(
											radioListDemolitionType,
											answers?.demolitionType
										)
									)}
								</>
							)}

							{isMoving(answers?.whatToDo) && (
								<>
									{questionAnswer(
										typeOfBuildingToMoveInput.label,
										getLabelFromList(
											radioListTypeOfBuildingToDemolish,
											answers?.typeOfBuildingToMove
										)
									)}

									{answers?.typeOfBuildingToMove?.toString() ==
										radioListTypeOfBuildingToDemolish[2].value &&
										questionAnswer(
											typeOfBuildingToMovePrecisionInput.label,
											answers?.typeOfBuildingToMovePrecision
										)}

									{questionAnswer(
										isTheBuildingToMoveInput.label,
										getLabelFromList(
											radioListIsTheBuildingToMove,
											answers?.buildingToMoveIs
										)
									)}

									{questionAnswer(
										isLocationInLavalInput.label,
										getLabelFromList(radioListYesNo, answers?.isLocationInLaval)
									)}

									{answers?.isLocationInLaval?.toString() ==
										radioListYesNo[0].value && (
										<>
											{questionAnswer(
												isLocationInLavalPrecisionInput.label,
												answers?.isLocationInLavalPrecision
											)}
										</>
									)}

									{questionAnswer(
										reasonForMovingBuildingInput.label,
										answers?.reasonForMovingBuilding
									)}
								</>
							)}
						</Collapse>
					</>
				)}

				{subSteps?.details_5 && (
					<>
						<Collapse
							id={5}
							buttonText={pageAssets?.request_form_information_on_the_work}
							isOpen={toPrint}
							key={`request_form_information_on_the_work`}
						>
							<div style={{ position: 'relative' }}>
								{!toPrint && (
									<a
										style={{
											display: toPrint ? 'none' : 'flex'
										}}
										className={joinClasses([
											pageUtils.classes.btn,
											pageUtils.classes.button,
											pageUtils.classes.buttonOutline,
											pageUtils.classes.summaryCollapseButton
										])}
										onClick={() =>
											backToForm(
												steps?.form!,
												REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_5,
												5
											)
										}
									>
										{pageAssets?.label_modify}
									</a>
								)}

								{toPrint && (
									<h4 className={pageUtils.classes.h4}>
										{pageAssets?.request_form_information_on_the_work}
									</h4>
								)}
							</div>

							{/* Question 24 */}
							<div>
								<strong>{Q24IsWorkDueToDisasterInput.label}</strong>
								<br />
								<span className={pageUtils.classes.answerURB}>
									{getLabelFromList(
										radioListYesNo,
										answers?.isWorkDueToDisaster
									)}
								</span>
							</div>

							{answers?.isWorkDueToDisaster?.toString() ==
								radioListYesNo[0].value && (
								<>
									<div>
										<strong>
											{Q24_1WorkDueToDisasterPrecisionInput.label}
										</strong>
										<br />
										<span className={pageUtils.classes.answerURB}>
											{answers?.workDueToDisasterPrecision}
										</span>
									</div>
								</>
							)}

							{/* Question 25 */}
							<div>
								<strong>{Q25ApproximateCostOfWorkInput.label}</strong>
								<br />
								<span className={pageUtils.classes.answerURB}>
									{formatNumberWithSpaces(
										answers?.approximateCostOfWork?.toString()
									)}{' '}
									$
								</span>
							</div>

							{/* Question 27 */}
							{questionAnswer(
								Q27IsTreeCuttingRequiredInput.label,
								getLabelFromList(radioListYesNo, answers?.isTreeCuttingRequired)
							)}

							{/* Question 28 */}
							{questionAnswer(
								Q28AdditionalInfoInput.label,
								answers?.additionalInfoQ28
							)}

							{/* Dates des travaux */}
							{(answers?.workStartDate || answers?.workEndDate) && (
								<h5 className={pageUtils.classes.h5}>
									{
										pageAssets?.request_form_buildingConstructionOrAdditionPermit_work_dates
									}
								</h5>
							)}

							{answers?.workStartDate && (
								<div>
									<strong>{Q29StartDateInput.label}</strong>
									<br />
									<span className={pageUtils.classes.answerURB}>
										{pageUtils.getLocaleDate(answers?.workStartDate!)}
									</span>
								</div>
							)}

							{answers?.workEndDate && (
								<div>
									<strong>{Q30EndDateInput.label}</strong>
									<br />
									<span className={pageUtils.classes.answerURB}>
										{pageUtils.getLocaleDate(answers?.workEndDate!)}
									</span>
								</div>
							)}

							<h5 className={pageUtils.classes.h5}>
								{
									pageAssets?.request_form_buildingConstructionOrAdditionPermit_entrepreneur_executing_work
								}
							</h5>

							{/* Question 31 */}
							{questionAnswer(
								Q31WhoWillBeInChargeOfWorkInput.label,
								getLabelFromList(
									radioListWhoWillBeInChargeOfWork,
									answers?.whoWillBeInChargeOfWork
								)
							)}

							{[
								radioListWhoWillBeInChargeOfWork[1].value,
								radioListWhoWillBeInChargeOfWork[2].value
							].includes(answers?.whoWillBeInChargeOfWork?.toString()!) &&
								questionAnswer(
									Q31_1EntrepreneurUnknownInput.label,
									getLabelFromList(
										radioListYesNo,
										answers?.entrepreneurUnknown1?.toString() || String(false)
									)
								)}

							{questionAnswer(
								Q32CompanyNameExecutingWorkInput.label,
								answers?.companyNameExecutingWork
							)}

							{questionAnswer(
								Q33CompanyNEQNumberInput.label,
								answers?.companyNEQNumber
							)}

							{questionAnswer(
								Q34CompanyRBQNumberInput.label,
								answers?.companyRBQNumber
							)}

							{(answers?.companyRepresentativeName ||
								answers?.companyRepresentativePhoneNumber ||
								answers?.companyRepresentativeEmail) && (
								<h6 className={pageUtils.classes.h6}>
									{
										pageAssets?.request_form_buildingConstructionOrAdditionPermit_representative_or_contact_person
									}
								</h6>
							)}

							{questionAnswer(
								Q35CompanyRepresentativeNameInput.label,
								answers?.companyRepresentativeName
							)}

							{questionAnswer(
								Q36CompanyRepresentativePhoneNumberInput.label,
								answers?.companyRepresentativePhoneNumber
							)}

							{questionAnswer(
								Q37CompanyRepresentativeEmailInput.label,
								answers?.companyRepresentativeEmail
							)}

							{(answers?.companyAddress ||
								answers?.companyCity ||
								answers?.companyPostalCode) && (
								<h6 className={pageUtils.classes.h6}>
									{
										pageAssets?.request_form_buildingConstructionOrAdditionPermit_company_address
									}
								</h6>
							)}

							{questionAnswer(
								Q38CompanyAddressInput.label,
								answers?.companyAddress
							)}

							{questionAnswer(Q39CompanyCityInput.label, answers?.companyCity)}

							{questionAnswer(
								Q40CompanyPostalCodeInput.label,
								answers?.companyPostalCode
							)}
						</Collapse>
					</>
				)}

				{subSteps?.details_6 && (
					<>
						<Collapse
							id={6}
							buttonText={pageAssets?.request_form_branching_aqueduc_egouts}
							isOpen={toPrint}
							key={`request_form_branching_aqueduc_egouts`}
						>
							<div style={{ position: 'relative' }}>
								{!toPrint && (
									<a
										style={{
											display: toPrint ? 'none' : 'flex'
										}}
										className={joinClasses([
											pageUtils.classes.btn,
											pageUtils.classes.button,
											pageUtils.classes.buttonOutline,
											pageUtils.classes.summaryCollapseButton
										])}
										onClick={() =>
											backToForm(
												steps?.form!,
												REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_6,
												6
											)
										}
									>
										{pageAssets?.label_modify}
									</a>
								)}
								{toPrint && (
									<h4 className={pageUtils.classes.h4}>
										{pageAssets?.request_form_branching_aqueduc_egouts}
									</h4>
								)}
							</div>
							{questionAnswer(
								Q41IsWaterAndSewerConnectionRequiredInput.label,
								getLabelFromList(
									radioListYesNo,
									answers?.isWaterAndSewerConnectionRequired?.toString() ||
										String(false)
								)
							)}

							{answers?.isWaterAndSewerConnectionRequired?.toString() ==
								'true' && (
								<>
									{answers?.isPropertyConnectedToMunicipalConduits && (
										<div>
											<strong>
												{Q41IsPropertyConnectedToMunicipalConduitsInput.label}
											</strong>
											<br />
											<span className={pageUtils.classes.answerURB}>
												<>
													{getLabelsFromString(
														radioListIsPropertyConnectedToMunicipalConduits,
														answers?.isPropertyConnectedToMunicipalConduits
													).map((answerLabel, index) => {
														return (
															<p
																className={pageUtils.classes.answerURB}
																key={`${index}-isPropertyConnectedToMunicipalConduits`}
															>
																{answerLabel}
															</p>
														)
													})}
												</>
											</span>
										</div>
									)}
									{questionAnswer(
										Q42AdditionalInfoInput.label,
										answers?.additionalInfoQ42
									)}
								</>
							)}
							{answers?.isWaterAndSewerConnectionRequired?.toString() ==
								'true' &&
								isMoving(answers?.whatToDo) && (
									<>
										{answers?.plannedWorkMovingBuilding && (
											<div>
												<strong>{Q43PlannedWorkInput.label}</strong>
												<br />
												<span className={pageUtils.classes.answerURB}>
													<>
														{getLabelsFromString(
															listPlannedWork,
															answers?.plannedWorkMovingBuilding
														).map((answerLabel, index) => {
															return (
																<p
																	className={pageUtils.classes.answerURB}
																	key={`${index}-plannedWork`}
																>
																	{answerLabel}
																</p>
															)
														})}
													</>
												</span>
											</div>
										)}
										{questionAnswer(
											Q44AdditionalInfoInput.label,
											answers?.plannedWorkMovingBuildingPrecision
										)}
									</>
								)}
						</Collapse>
					</>
				)}

				{subSteps?.requiredDocument && (
					<>
						<Collapse
							id={7}
							buttonText={pageAssets?.request_form_required_documents}
							isOpen={toPrint}
							key={`request_form_required_documents`}
						>
							<div style={{ position: 'relative' }}>
								{!toPrint && (
									<a
										style={{
											display: toPrint ? 'none' : 'flex'
										}}
										className={joinClasses([
											pageUtils.classes.btn,
											pageUtils.classes.button,
											pageUtils.classes.buttonOutline,
											pageUtils.classes.summaryCollapseButton
										])}
										onClick={() =>
											backToForm(
												steps?.form!,
												REQUEST_TN_DETAILS_STEPS_NAME.DOCUMENTS,
												7
											)
										}
									>
										{pageAssets?.label_modify}
									</a>
								)}
								{toPrint && (
									<h4 className={pageUtils.classes.h4}>
										{pageAssets?.request_form_required_documents}
									</h4>
								)}
							</div>

							{isDemolition(answers?.whatToDo) && (
								<>
									<RequestFiles
										subtitle={thirdQuestion.label}
										name={thirdQuestion.name}
										required={thirdQuestion.required}
										files={thirdQuestion.files}
										hasError={errors.includes(thirdQuestion.name)}
										removeSectionMarginTop
										removePadding
										equivalentMargin
										onSetHasError={onSetErrorFromFileComponent}
										onSetFiles={onSelectFileThirdQuestion}
										maxFiles={thirdQuestion.numberFilesRequired}
										isSummary
									>
										<></>
									</RequestFiles>

									<RequestFiles
										subtitle={fourthQuestion.label}
										name={fourthQuestion.name}
										required={fourthQuestion.required}
										files={fourthQuestion.files}
										hasError={errors.includes(fourthQuestion.name)}
										removeSectionMarginTop
										removePadding
										equivalentMargin
										onSetHasError={onSetErrorFromFileComponent}
										onSetFiles={onSelectFileFourthQuestion}
										maxFiles={fourthQuestion.numberFilesRequired}
										isSummary
									>
										<></>
									</RequestFiles>
								</>
							)}

							{isMoving(answers?.whatToDo) && (
								<>
									{renderMovingRequestFiles('required', true)}
									{renderMovingRequestFiles('optional', true)}
								</>
							)}
						</Collapse>
					</>
				)}
			</section>
		)
	}

	return (
		<>
			{subSteps?.position === REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_1 && (
				<ReactCollapse
					isOpened={
						subSteps !== undefined &&
						subSteps.position === REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_1
					}
				>
					<section>
						<h4 className={pageUtils.classes.h4}>
							{pageAssets?.request_form_type_de_demandes}
						</h4>

						{/* Question 1 */}
						<RadioInput
							name={whatToDoInput.name}
							label={whatToDoInput.label}
							value={whatToDoInput.value}
							list={radioListWhatToDo}
							direction={Direction.vertical}
							hasError={errors!.includes(whatToDoInput.name)}
							onChange={onSetWhatToDoInput}
							ref={whatToDoInput.ref as RefObject<HTMLInputElement>}
							required={whatToDoInput.required}
							hasBackgroundWrapper={true}
						/>

						{/* Question 2 */}
						<RadioInput
							name={requestAsInput.name}
							label={requestAsInput.label}
							value={requestAsInput.value}
							list={radioListRequestAs}
							direction={Direction.vertical}
							hasError={errors!.includes(requestAsInput.name)}
							onChange={onSetRequestAsInput}
							ref={requestAsInput.ref as RefObject<HTMLInputElement>}
							required={requestAsInput.required}
							hasBackgroundWrapper={true}
						/>

						{[
							REQUEST_CONFIGS_KEYS.requestAs.entreprise,
							REQUEST_CONFIGS_KEYS.requestAs.entrepreneur_or_manager
						].includes(requestAsInput.value) && (
							<>
								{/* Question 2.1 */}
								<TextInput
									id={companyNameInput.name}
									label={companyNameInput.label}
									value={companyNameInput.value}
									onChange={onSetCompanyNameInput}
									hasError={errors?.includes(companyNameInput.name)}
									required={companyNameInput.required}
									ref={companyNameInput.ref as RefObject<HTMLInputElement>}
									maxCharacters={100}
								/>

								{/* Question 2.2 */}
								<TextInput
									id={representativeNameInput.name}
									label={representativeNameInput.label}
									value={representativeNameInput.value}
									onChange={onSetRepresentativeNameInput}
									hasError={errors?.includes(representativeNameInput.name)}
									required={representativeNameInput.required}
									ref={
										representativeNameInput.ref as RefObject<HTMLInputElement>
									}
									maxCharacters={100}
								/>
							</>
						)}

						{isMoving(whatToDoInput.value) && (
							<>
								{/* Architectural implementation and integration plan (PIIA) */}
								<h5 className={pageUtils.classes.h5}>
									{
										pageAssets?.request_form_buildingConstructionOrAdditionPermit_piia_title
									}
								</h5>

								{/* Question 3 */}
								<RadioInput
									name={isSubjectToPiiaApprovalInput.name}
									label={isSubjectToPiiaApprovalInput.label}
									value={isSubjectToPiiaApprovalInput.value}
									list={radioListYesNo}
									direction={Direction.vertical}
									hasError={errors!.includes(isSubjectToPiiaApprovalInput.name)}
									onChange={onSetIsSubjectToPiiaApprovalInput}
									ref={
										isSubjectToPiiaApprovalInput.ref as RefObject<HTMLInputElement>
									}
									required={isSubjectToPiiaApprovalInput.required}
									hasBackgroundWrapper={true}
									classWrapper={classes.reducedMarginBottom}
									description={
										pageAssets?.request_form_buildingConstructionOrAdditionPermit_isSubjectToPiiaApproval_description
									}
								/>

								{isSubjectToPiiaApprovalInput.value ==
									radioListYesNo[0].value && (
									<>
										{/* Question 4 */}
										<RadioInput
											name={hasBeenSubjectToApprovalInput.name}
											label={hasBeenSubjectToApprovalInput.label}
											value={hasBeenSubjectToApprovalInput.value}
											list={radioListYesNo}
											direction={Direction.vertical}
											hasError={errors!.includes(
												hasBeenSubjectToApprovalInput.name
											)}
											onChange={onSetHasBeenSubjectToApprovalInput}
											ref={
												hasBeenSubjectToApprovalInput.ref as RefObject<HTMLInputElement>
											}
											required={hasBeenSubjectToApprovalInput.required}
											hasBackgroundWrapper={true}
											classWrapper={classes.reducedMarginBottom}
										/>
										{hasBeenSubjectToApprovalInput.value ==
											radioListYesNo[0].value && (
											<>
												<MaskedTextInput
													id={iaNumberInput.name}
													label={iaNumberInput.label}
													value={iaNumberInput.value}
													onChange={onSetIaNumberInput}
													hasError={errors.includes(iaNumberInput.name)}
													required={iaNumberInput.required}
													ref={iaNumberInput.ref as RefObject<HTMLInputElement>}
													max={12}
													min={12}
													mask="IA-0000-0000"
													placeholder="IA-AAAA-XXXX"
													errors={errors}
													setError={onFixError}
													schema={/^IA-\d{4}-\d{4}$/}
												/>

												<MaskedTextInput
													id={ecResolutionNumberInput.name}
													label={ecResolutionNumberInput.label}
													value={ecResolutionNumberInput.value}
													onChange={onSetEcResolutionNumberInput}
													hasError={errors.includes(
														ecResolutionNumberInput.name
													)}
													required={ecResolutionNumberInput.required}
													ref={
														ecResolutionNumberInput.ref as RefObject<HTMLInputElement>
													}
													max={16}
													min={16}
													mask="CE-00000000-0000"
													placeholder="CE-AAAAMMJJ-XXXX"
													errors={errors}
													setError={onFixError}
													schema={/^CE-\d{8}-\d{4}$/}
												/>
											</>
										)}
									</>
								)}
							</>
						)}
					</section>
				</ReactCollapse>
			)}

			{subSteps?.position === REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_2 && (
				<ReactCollapse
					isOpened={
						subSteps !== undefined &&
						subSteps.position === REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_2
					}
				>
					<section>
						<h4 className={pageUtils.classes.h4}>
							{pageAssets?.request_form_request_location}
						</h4>

						<div className={classes.adresseBlock}>
							<div className={pageUtils.classes.fieldsContainer}>
								<div>
									<strong>{pageAssets?.label_address}</strong>
									<br />
									{authUser?.profile?.address.address}
									<br />
									{authUser?.profile?.address.city}
									{authUser?.profile?.address.state
										? ` (${authUser?.profile?.address.state}) `
										: ' '}
									{authUser?.profile?.address.postalCode}
									<br />
									{authUser?.profile?.address.apartment
										? `${formatStrapiText(
												pageAssets?.label_apartment_office
										  )} ${authUser?.profile?.address.apartment}`
										: ''}
								</div>

								{buildingBatchNumberInput.value && (
									<div>
										<strong>{pageAssets?.lot_number_label}</strong>
										<br />
										{buildingBatchNumberInput.value}
									</div>
								)}
							</div>
						</div>

						{/* Question 7 */}

						<RadioInput
							name={isSameLocationInput.name}
							label={isSameLocationInput.label}
							value={isSameLocationInput.value}
							list={radioListYesNo}
							direction={Direction.vertical}
							hasError={errors!.includes(isSameLocationInput.name)}
							onChange={onSetIsSameLocationInput}
							ref={isSameLocationInput.ref as RefObject<HTMLInputElement>}
							required={isSameLocationInput.required}
							hasBackgroundWrapper={true}
							classWrapper={classes.reducedMarginBottom}
						/>

						{/* Question 8 */}
						{showAdresseBlock == true && (
							<>
								<AddressVoiePublicSection
									label={pageAssets?.request_form_location}
									errors={errors}
									inputs={inputs}
									onFixError={onFixError}
									onSetInputs={onSetInputs}
									isConcernedInfosRequired={
										isSameLocationInput.value == radioListYesNo[1].value
									}
									showButtonUserLocation={false}
									displayAddressDetails={true}
									requiredFields={[
										'addressOption',
										'addressField',
										'addressLotNumber'
									]}
									subStep={REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_2}
									addressOptions={radioListAddressOption}
								/>

								{/* If your construction is new and you do not yet have a valid address, please enter a lot number instead. */}
								{isDemolition(whatToDoInput?.value) && (
									<>
										<br />
										<Notification
											text={
												pageAssets?.request_form_buildingDemolitionOrMovingPermit_checkIfSiteCoveredByHritageBuilding_notification_label
											}
											type="info"
											hasHtml
										/>
									</>
								)}
								<br />
							</>
						)}

						{isSameLocationInput.value == radioListYesNo[0].value &&
							isAddressOutsideLaval && (
								<Notification
									text={
										pageAssets?.request_form_isAddressOutsideLavalMessageShow_notification_label
									}
									type="warning"
									hasHtml
								/>
							)}

						{/* Question 9 */}
						<RadioInput
							name={isLocationOwnedInput.name}
							label={isLocationOwnedInput.label}
							value={isLocationOwnedInput.value}
							list={radioListYesNo}
							direction={Direction.vertical}
							hasError={errors!.includes(isLocationOwnedInput.name)}
							onChange={onSetIsLocationOwnedInput}
							ref={isLocationOwnedInput.ref as RefObject<HTMLInputElement>}
							required={isLocationOwnedInput.required}
							hasBackgroundWrapper={true}
							classWrapper={classes.reducedMarginBottom}
						/>

						{/* ****** Owner inputs ****** */}
						{isLocationOwnedInput.value == radioListYesNo[1].value && (
							<>
								<Notification
									text={
										pageAssets?.request_form_ownerPowerOfAttorney_notification
									}
									type="info"
									hasHtml
								/>
								<div
									className={joinClasses([
										pageUtils.classes.fieldsContainer,
										classes.greyBackgroundBlock
									])}
								>
									<TextInput
										id={ownerFirstNameInput.name}
										label={ownerFirstNameInput.label}
										value={ownerFirstNameInput.value}
										onChange={onSetOwnerFirstNameInput}
										hasError={errors.includes(ownerFirstNameInput.name)}
										required={ownerFirstNameInput.required}
										ref={ownerFirstNameInput.ref as RefObject<HTMLInputElement>}
										classWrapper={pageUtils?.classes.textFieldContainer}
										maxCharacters={50}
									/>
									<TextInput
										id={ownerLastNameInput.name}
										label={ownerLastNameInput.label}
										value={ownerLastNameInput.value}
										onChange={onSetOwnerLastNameInput}
										hasError={errors.includes(ownerLastNameInput.name)}
										required={ownerLastNameInput.required}
										ref={ownerLastNameInput.ref as RefObject<HTMLInputElement>}
										classWrapper={pageUtils?.classes.textFieldContainer}
										maxCharacters={50}
									/>

									<TextInput
										id={ownerEmailInput.name}
										label={ownerEmailInput.label}
										value={ownerEmailInput.value}
										onChange={onSetOwnerEmailInput}
										hasError={errors?.includes(ownerEmailInput.name)}
										type="email"
										onSetError={onFixError}
										schema={formUtils.MASKS.email}
										schemaErrorText={pageAssets?.label_emailErrorText}
										required={ownerEmailInput.required}
										ref={ownerEmailInput.ref as RefObject<HTMLInputElement>}
										maxCharacters={100}
									/>
									<MaskedTextInput
										id={ownerPhoneNumberInput.name}
										label={ownerPhoneNumberInput.label}
										value={ownerPhoneNumberInput.value}
										onChange={onSetOwnerPhoneNumberInput}
										hasError={errors?.includes(ownerPhoneNumberInput.name)}
										type="tel"
										min={10}
										max={14}
										mask={formUtils.MASKS.phoneNumber}
										placeholder={formUtils.MASKS.phoneNumber}
										errors={errors ? errors : []}
										setError={onFixError}
										schema={PHONE_NUMBER_REGEX}
										required={ownerPhoneNumberInput.required}
										ref={
											ownerPhoneNumberInput.ref as RefObject<HTMLInputElement>
										}
									/>
								</div>

								<RequestFiles
									subtitle={firstQuestion.label}
									name={firstQuestion.name}
									required
									files={firstQuestion.files}
									hasError={errors.includes(firstQuestion.name)}
									removeSectionMarginTop
									removePadding
									equivalentMargin
									onSetHasError={onSetErrorFromFileComponent}
									onSetFiles={onSelectFileFirstQuestion}
									subSteps={subSteps}
									maxFiles={firstQuestion.numberMaxFiles}
									isNewDesign
								></RequestFiles>
								<br />
							</>
						)}
					</section>
				</ReactCollapse>
			)}

			{subSteps?.position === REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_3 && (
				<ReactCollapse
					isOpened={
						subSteps !== undefined &&
						subSteps.position === REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_3
					}
				>
					<section>
						<h4 className={pageUtils.classes.h4}>
							{pageAssets?.request_form_type_of_use}
						</h4>

						<RadioInput
							name={mainUseOfExistingBuildingInput.name}
							label={mainUseOfExistingBuildingInput.label}
							value={mainUseOfExistingBuildingInput.value}
							list={radioListMainUseOfExistingBuilding}
							direction={Direction.vertical}
							hasError={errors!.includes(mainUseOfExistingBuildingInput.name)}
							onChange={onSetMainUseOfExistingBuildingInput}
							ref={
								mainUseOfExistingBuildingInput.ref as RefObject<HTMLInputElement>
							}
							required={mainUseOfExistingBuildingInput.required}
							hasBackgroundWrapper={true}
						/>

						<RadioInput
							name={structureTypeInput.name}
							label={structureTypeInput.label}
							value={structureTypeInput.value}
							list={radioListStructureType}
							direction={Direction.vertical}
							hasError={errors!.includes(structureTypeInput.name)}
							onChange={onSetStructureTypeInput}
							ref={structureTypeInput.ref as RefObject<HTMLInputElement>}
							required={structureTypeInput.required}
							hasBackgroundWrapper={true}
						/>

						{/* Question 11 */}
						<RadioInput
							name={isTheNewBuildingInput.name}
							label={isTheNewBuildingInput.label}
							value={isTheNewBuildingInput.value}
							list={radioListIsTheNewBuilding}
							direction={Direction.vertical}
							hasError={errors!.includes(isTheNewBuildingInput.name)}
							onChange={onSetIsTheNewBuildingInput}
							ref={isTheNewBuildingInput.ref as RefObject<HTMLInputElement>}
							required={isTheNewBuildingInput.required}
							hasBackgroundWrapper={true}
						/>

						{/* Question 12 */}
						<RadioInput
							name={isTheNewBuildingPartOfAPropertyInput.name}
							label={isTheNewBuildingPartOfAPropertyInput.label}
							value={isTheNewBuildingPartOfAPropertyInput.value}
							list={radioListYesNo}
							direction={Direction.vertical}
							hasError={errors!.includes(
								isTheNewBuildingPartOfAPropertyInput.name
							)}
							onChange={onSetIsTheNewBuildingPartOfAPropertyInput}
							ref={
								isTheNewBuildingPartOfAPropertyInput.ref as RefObject<HTMLInputElement>
							}
							required={isTheNewBuildingPartOfAPropertyInput.required}
							hasBackgroundWrapper={true}
						/>

						{isTheNewBuildingPartOfAPropertyInput.value ==
							radioListYesNo[0].value && (
							<>
								<RequestFiles
									subtitle={secondQuestion.label}
									name={secondQuestion.name}
									required
									files={secondQuestion.files}
									hasError={errors.includes(secondQuestion.name)}
									removeSectionMarginTop
									removePadding
									equivalentMargin
									onSetHasError={onSetErrorFromFileComponent}
									onSetFiles={onSelectFileSecondQuestion}
									subSteps={subSteps}
									maxFiles={secondQuestion.numberMaxFiles}
									isNewDesign
								></RequestFiles>
								<br />
							</>
						)}
					</section>
				</ReactCollapse>
			)}

			{subSteps?.position === REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_4 && (
				<ReactCollapse
					isOpened={
						subSteps !== undefined &&
						subSteps.position === REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_4
					}
				>
					<section>
						<h4 className={pageUtils.classes.h4}>
							{isDemolition(whatToDoInput.value) &&
								pageAssets?.request_form_building_demolition}
							{isMoving(whatToDoInput.value) &&
								pageAssets?.request_form_building_moving}
						</h4>

						{isDemolition(whatToDoInput.value) && (
							<>
								<RadioInput
									name={typeOfBuildingToDemolishInput.name}
									label={typeOfBuildingToDemolishInput.label}
									value={typeOfBuildingToDemolishInput.value}
									list={radioListTypeOfBuildingToDemolish}
									direction={Direction.vertical}
									hasError={errors!.includes(
										typeOfBuildingToDemolishInput.name
									)}
									required={typeOfBuildingToDemolishInput.required}
									ref={
										typeOfBuildingToDemolishInput.ref as RefObject<HTMLInputElement>
									}
									hasBackgroundWrapper={true}
									onChange={onSetTypeOfBuildingToDemolishInput}
									classWrapper={classes.reducedMarginBottom}
								/>

								{typeOfBuildingToDemolishInput.value ==
									radioListTypeOfBuildingToDemolish[2].value && (
									<TextInput
										id={typeOfBuildingToDemolishPrecisionInput.name}
										label={typeOfBuildingToDemolishPrecisionInput.label}
										value={typeOfBuildingToDemolishPrecisionInput.value}
										onChange={onSetTypeOfBuildingToDemolishPrecisionInput}
										hasError={errors!.includes(
											typeOfBuildingToDemolishPrecisionInput.name
										)}
										required={typeOfBuildingToDemolishPrecisionInput.required}
										ref={
											typeOfBuildingToDemolishPrecisionInput.ref as RefObject<HTMLInputElement>
										}
										maxCharacters={100}
									/>
								)}

								<RadioInput
									name={demolitionTypeInput.name}
									label={demolitionTypeInput.label}
									value={demolitionTypeInput.value}
									list={radioListDemolitionType}
									direction={Direction.vertical}
									hasError={errors!.includes(demolitionTypeInput.name)}
									required={demolitionTypeInput.required}
									ref={demolitionTypeInput.ref as RefObject<HTMLInputElement>}
									hasBackgroundWrapper={true}
									onChange={onSetDemolitionTypeInput}
									classWrapper={classes.reducedMarginBottom}
								/>
							</>
						)}

						{isMoving(whatToDoInput.value) && (
							<>
								<RadioInput
									name={typeOfBuildingToMoveInput.name}
									label={typeOfBuildingToMoveInput.label}
									value={typeOfBuildingToMoveInput.value}
									list={radioListTypeOfBuildingToDemolish}
									direction={Direction.vertical}
									hasError={errors!.includes(typeOfBuildingToMoveInput.name)}
									required={typeOfBuildingToMoveInput.required}
									ref={
										typeOfBuildingToMoveInput.ref as RefObject<HTMLInputElement>
									}
									hasBackgroundWrapper={true}
									onChange={onSetTypeOfBuildingToMoveInput}
									classWrapper={classes.reducedMarginBottom}
								/>

								{typeOfBuildingToMoveInput.value ==
									radioListTypeOfBuildingToDemolish[2].value && (
									<TextInput
										id={typeOfBuildingToMovePrecisionInput.name}
										label={typeOfBuildingToMovePrecisionInput.label}
										value={typeOfBuildingToMovePrecisionInput.value}
										onChange={onSetTypeOfBuildingToMovePrecisionInput}
										hasError={errors!.includes(
											typeOfBuildingToMovePrecisionInput.name
										)}
										required={typeOfBuildingToMovePrecisionInput.required}
										ref={
											typeOfBuildingToMovePrecisionInput.ref as RefObject<HTMLInputElement>
										}
										maxCharacters={100}
									/>
								)}

								<RadioInput
									name={isTheBuildingToMoveInput.name}
									label={isTheBuildingToMoveInput.label}
									value={isTheBuildingToMoveInput.value}
									list={radioListIsTheBuildingToMove}
									direction={Direction.vertical}
									hasError={errors!.includes(isTheBuildingToMoveInput.name)}
									required={isTheBuildingToMoveInput.required}
									ref={
										isTheBuildingToMoveInput.ref as RefObject<HTMLInputElement>
									}
									hasBackgroundWrapper={true}
									onChange={onSetIsTheBuildingToMoveInput}
									classWrapper={classes.reducedMarginBottom}
								/>

								<RadioInput
									name={isLocationInLavalInput.name}
									label={isLocationInLavalInput.label}
									value={isLocationInLavalInput.value}
									list={radioListYesNo}
									direction={Direction.vertical}
									hasError={errors!.includes(isLocationInLavalInput.name)}
									required={isLocationInLavalInput.required}
									ref={
										isLocationInLavalInput.ref as RefObject<HTMLInputElement>
									}
									hasBackgroundWrapper={true}
									onChange={onSetIsLocationInLavalInput}
									classWrapper={classes.reducedMarginBottom}
								/>

								{isLocationInLavalInput.value == radioListYesNo[0].value && (
									<>
										{/* isLocationInLavalPrecisionInput */}
										<TextInput
											id={isLocationInLavalPrecisionInput.name}
											label={isLocationInLavalPrecisionInput.label}
											value={isLocationInLavalPrecisionInput.value}
											onChange={onSetIsLocationInLavalPrecisionInput}
											hasError={errors!.includes(
												isLocationInLavalPrecisionInput.name
											)}
											required={isLocationInLavalPrecisionInput.required}
											ref={
												isLocationInLavalPrecisionInput.ref as RefObject<HTMLInputElement>
											}
											maxCharacters={500}
										/>
									</>
								)}

								<TextInput
									id={reasonForMovingBuildingInput.name}
									label={reasonForMovingBuildingInput.label}
									value={reasonForMovingBuildingInput.value}
									onChange={onSetReasonForMovingBuildingInput}
									hasError={errors!.includes(reasonForMovingBuildingInput.name)}
									required={reasonForMovingBuildingInput.required}
									ref={
										reasonForMovingBuildingInput.ref as RefObject<HTMLInputElement>
									}
									maxCharacters={100}
								/>
							</>
						)}
					</section>
				</ReactCollapse>
			)}

			{subSteps?.position === REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_5 && (
				<ReactCollapse
					isOpened={
						subSteps !== undefined &&
						subSteps.position === REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_5
					}
				>
					<section>
						<h4 className={pageUtils.classes.h4}>
							{pageAssets?.request_form_information_on_the_work}
						</h4>

						<RadioInput
							name={Q24IsWorkDueToDisasterInput.name}
							label={Q24IsWorkDueToDisasterInput.label}
							value={Q24IsWorkDueToDisasterInput.value}
							list={radioListYesNo}
							direction={Direction.vertical}
							hasError={errors!.includes(Q24IsWorkDueToDisasterInput.name)}
							required={Q24IsWorkDueToDisasterInput.required}
							ref={
								Q24IsWorkDueToDisasterInput.ref as RefObject<HTMLInputElement>
							}
							hasBackgroundWrapper={true}
							onChange={onSetQ24IsWorkDueToDisasterInput}
							classWrapper={classes.reducedMarginBottom}
						/>

						{Q24IsWorkDueToDisasterInput.value == radioListYesNo[0].value && (
							<>
								<TextInput
									id={Q24_1WorkDueToDisasterPrecisionInput.name}
									label={Q24_1WorkDueToDisasterPrecisionInput.label}
									value={Q24_1WorkDueToDisasterPrecisionInput.value}
									onChange={onSetQ24_1WorkDueToDisasterPrecisionInput}
									hasError={errors!.includes(
										Q24_1WorkDueToDisasterPrecisionInput.name
									)}
									required={Q24_1WorkDueToDisasterPrecisionInput.required}
									ref={
										Q24_1WorkDueToDisasterPrecisionInput.ref as RefObject<HTMLInputElement>
									}
									maxCharacters={100}
								/>
							</>
						)}

						<MaskedThousandsSeparatorInput
							id={Q25ApproximateCostOfWorkInput.name}
							label={Q25ApproximateCostOfWorkInput.label}
							value={Q25ApproximateCostOfWorkInput.value}
							onChange={onSetQ25ApproximateCostOfWorkInput}
							hasError={errors.includes(Q25ApproximateCostOfWorkInput.name)}
							required={Q25ApproximateCostOfWorkInput.required}
							ref={
								Q25ApproximateCostOfWorkInput.ref as RefObject<HTMLInputElement>
							}
							errors={errors}
							setError={onFixError}
							displayCurrency
						/>

						<RadioInput
							name={Q27IsTreeCuttingRequiredInput.name}
							label={Q27IsTreeCuttingRequiredInput.label}
							value={Q27IsTreeCuttingRequiredInput.value}
							list={radioListYesNo}
							direction={Direction.vertical}
							hasError={errors!.includes(Q27IsTreeCuttingRequiredInput.name)}
							required={Q27IsTreeCuttingRequiredInput.required}
							ref={
								Q27IsTreeCuttingRequiredInput.ref as RefObject<HTMLInputElement>
							}
							hasBackgroundWrapper={true}
							onChange={onSetQ27IsTreeCuttingRequiredInput}
							description={Q27IsTreeCuttingRequiredInput.description}
						/>

						<TextAreaInput
							id={Q28AdditionalInfoInput.name}
							label={Q28AdditionalInfoInput.label}
							value={Q28AdditionalInfoInput.value}
							onChange={onSetQ28AdditionalInfoInput}
							hasError={errors!.includes(Q28AdditionalInfoInput.name)}
							required={Q28AdditionalInfoInput.required}
							ref={Q28AdditionalInfoInput.ref as RefObject<HTMLTextAreaElement>}
							maxCharacters={100}
							hasDescriptionLabel={Q28AdditionalInfoInput.description}
							displayDescriptionTop
							descriptionAlignment="left"
							rows={4}
						/>

						{/* Dates des travaux */}
						<h5 className={pageUtils.classes.h5}>
							{
								pageAssets?.request_form_buildingConstructionOrAdditionPermit_work_dates
							}
						</h5>

						<div className={pageUtils.classes.fieldsContainer}>
							<DateFilterInput
								id={Q29StartDateInput.name}
								label={Q29StartDateInput.label}
								value={Q29StartDateInput.value}
								onChange={onSetQ29StartDateInput}
								hasError={errors!.includes(Q29StartDateInput.name)}
								required={Q29StartDateInput.required}
								dateFormat="dd/MM/yyyy"
								minDate={new Date()}
							/>

							<DateFilterInput
								id={Q30EndDateInput.name}
								label={Q30EndDateInput.label}
								value={Q30EndDateInput.value}
								onChange={onSetQ30EndDateInput}
								hasError={errors!.includes(Q30EndDateInput.name)}
								required={Q30EndDateInput.required}
								dateFormat="dd/MM/yyyy"
								minDate={new Date(Q29StartDateInput.value + 'T12:00:00')}
								disabled={Q29StartDateInput.value == ''}
							/>
						</div>

						{/* Entrepreneur exécutant les travaux de construction. */}
						<h5 className={pageUtils.classes.h5}>
							{
								pageAssets?.request_form_buildingConstructionOrAdditionPermit_entrepreneur_executing_work
							}
						</h5>

						<RadioInput
							name={Q31WhoWillBeInChargeOfWorkInput.name}
							label={Q31WhoWillBeInChargeOfWorkInput.label}
							value={Q31WhoWillBeInChargeOfWorkInput.value}
							list={
								requestAsInput.value ==
								radioListWhoWillBeInChargeOfWork[0].value
									? radioListWhoWillBeInChargeOfWork
									: radioListWhoWillBeInChargeOfWork.slice(1)
							}
							direction={Direction.vertical}
							hasError={errors!.includes(Q31WhoWillBeInChargeOfWorkInput.name)}
							required={Q31WhoWillBeInChargeOfWorkInput.required}
							ref={
								Q31WhoWillBeInChargeOfWorkInput.ref as RefObject<HTMLInputElement>
							}
							hasBackgroundWrapper={true}
							onChange={onSetQ31WhoWillBeInChargeOfWorkInput}
						/>

						{/* <br /> */}
						{[
							radioListWhoWillBeInChargeOfWork[1].value,
							radioListWhoWillBeInChargeOfWork[2].value
						].includes(Q31WhoWillBeInChargeOfWorkInput.value) && (
							<div className={classes.checkbox2Container}>
								<span
									className={classes.checkboxWrapper}
									style={{ marginTop: '10px' }}
								>
									<input
										onClick={(e: any) =>
											onSetQ31_1EntrepreneurUnknownInput(e.target.checked)
										}
										value={Q31_1EntrepreneurUnknownInput.value?.toString()}
										className={classes.checkbox2}
										checked={
											Q31_1EntrepreneurUnknownInput.value.toString() == 'true'
										}
										type="checkbox"
										name={Q31_1EntrepreneurUnknownInput.name}
										id={Q31_1EntrepreneurUnknownInput.labelKey}
									/>
									<label
										className={classes.checkbox2Label}
										htmlFor={Q31_1EntrepreneurUnknownInput.labelKey}
									>
										{Q31_1EntrepreneurUnknownInput.label}
									</label>
								</span>
							</div>
						)}

						<TextInput
							id={Q32CompanyNameExecutingWorkInput.name}
							label={Q32CompanyNameExecutingWorkInput.label}
							value={Q32CompanyNameExecutingWorkInput.value}
							onChange={onSetQ32CompanyNameExecutingWorkInput}
							hasError={errors!.includes(Q32CompanyNameExecutingWorkInput.name)}
							required={Q32CompanyNameExecutingWorkInput.required}
							ref={
								Q32CompanyNameExecutingWorkInput.ref as RefObject<HTMLInputElement>
							}
							maxCharacters={50}
						/>
						<div className={pageUtils.classes.fieldsContainer}>
							<TextInput
								id={Q33CompanyNEQNumberInput.name}
								label={Q33CompanyNEQNumberInput.label}
								value={Q33CompanyNEQNumberInput.value}
								onChange={onSetQ33CompanyNEQNumberInput}
								hasError={errors!.includes(Q33CompanyNEQNumberInput.name)}
								required={Q33CompanyNEQNumberInput.required}
								ref={
									Q33CompanyNEQNumberInput.ref as RefObject<HTMLInputElement>
								}
								maxCharacters={20}
							/>

							<TextInput
								id={Q34CompanyRBQNumberInput.name}
								label={Q34CompanyRBQNumberInput.label}
								value={Q34CompanyRBQNumberInput.value}
								onChange={onSetQ34CompanyRBQNumberInput}
								hasError={errors!.includes(Q34CompanyRBQNumberInput.name)}
								required={Q34CompanyRBQNumberInput.required}
								ref={
									Q34CompanyRBQNumberInput.ref as RefObject<HTMLInputElement>
								}
								maxCharacters={20}
							/>
						</div>

						{/* Représentant(e) ou personne contact */}
						<h6 className={pageUtils.classes.h6} style={{ marginTop: '20px' }}>
							{
								pageAssets?.request_form_buildingConstructionOrAdditionPermit_representative_or_contact_person
							}
						</h6>

						<TextInput
							id={Q35CompanyRepresentativeNameInput.name}
							label={Q35CompanyRepresentativeNameInput.label}
							value={Q35CompanyRepresentativeNameInput.value}
							onChange={onSetQ35CompanyRepresentativeNameInput}
							hasError={errors!.includes(
								Q35CompanyRepresentativeNameInput.name
							)}
							required={Q35CompanyRepresentativeNameInput.required}
							ref={
								Q35CompanyRepresentativeNameInput.ref as RefObject<HTMLInputElement>
							}
							maxCharacters={100}
						/>

						<div className={pageUtils.classes.fieldsContainer}>
							<MaskedTextInput
								id={Q36CompanyRepresentativePhoneNumberInput.name}
								label={Q36CompanyRepresentativePhoneNumberInput.label}
								value={Q36CompanyRepresentativePhoneNumberInput.value}
								onChange={onSetQ36CompanyRepresentativePhoneNumberInput}
								hasError={errors?.includes(
									Q36CompanyRepresentativePhoneNumberInput.name
								)}
								type="tel"
								min={10}
								max={14}
								mask={formUtils.MASKS.phoneNumber}
								placeholder={formUtils.MASKS.phoneNumber}
								errors={errors ? errors : []}
								setError={onFixError}
								schema={PHONE_NUMBER_REGEX}
								required={Q36CompanyRepresentativePhoneNumberInput.required}
								ref={
									Q36CompanyRepresentativePhoneNumberInput.ref as RefObject<HTMLInputElement>
								}
							/>

							<TextInput
								id={Q37CompanyRepresentativeEmailInput.name}
								label={Q37CompanyRepresentativeEmailInput.label}
								value={Q37CompanyRepresentativeEmailInput.value}
								onChange={onSetQ37CompanyRepresentativeEmailInput}
								hasError={errors!.includes(
									Q37CompanyRepresentativeEmailInput.name
								)}
								required={Q37CompanyRepresentativeEmailInput.required}
								ref={
									Q37CompanyRepresentativeEmailInput.ref as RefObject<HTMLInputElement>
								}
								maxCharacters={100}
								type="email"
								schema={formUtils.MASKS.email}
								schemaErrorText={pageAssets.label_emailErrorText}
								onSetError={onFixError}
							/>
						</div>

						{/* Adresse de l'entreprise */}
						<h6 className={pageUtils.classes.h6} style={{ marginTop: '20px' }}>
							{
								pageAssets?.request_form_buildingConstructionOrAdditionPermit_company_address
							}
						</h6>

						<TextInput
							id={Q38CompanyAddressInput.name}
							label={Q38CompanyAddressInput.label}
							value={Q38CompanyAddressInput.value}
							onChange={onSetQ38CompanyAddressInput}
							hasError={errors!.includes(Q38CompanyAddressInput.name)}
							required={Q38CompanyAddressInput.required}
							ref={Q38CompanyAddressInput.ref as RefObject<HTMLInputElement>}
							maxCharacters={50}
						/>

						<div className={pageUtils.classes.fieldsContainer}>
							<TextInput
								id={Q39CompanyCityInput.name}
								label={Q39CompanyCityInput.label}
								value={Q39CompanyCityInput.value}
								onChange={onSetQ39CompanyCityInput}
								hasError={errors!.includes(Q39CompanyCityInput.name)}
								required={Q39CompanyCityInput.required}
								ref={Q39CompanyCityInput.ref as RefObject<HTMLInputElement>}
								maxCharacters={50}
							/>

							<MaskedTextInput
								id={Q40CompanyPostalCodeInput.name}
								label={Q40CompanyPostalCodeInput.label}
								value={Q40CompanyPostalCodeInput.value}
								onChange={onSetQ40CompanyPostalCodeInput}
								hasError={errors!.includes(Q40CompanyPostalCodeInput.name)}
								required={Q40CompanyPostalCodeInput.required}
								ref={
									Q40CompanyPostalCodeInput.ref as RefObject<HTMLInputElement>
								}
								max={7}
								min={7}
								mask={formUtils.MASKS.postalCode}
								placeholder="A1A 1A1"
								errors={errors}
								setError={onFixError}
								schema={POSTAL_CODE_REGEX}
							/>
						</div>
					</section>
				</ReactCollapse>
			)}

			{subSteps?.position === REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_6 && (
				<ReactCollapse
					isOpened={
						subSteps !== undefined &&
						subSteps.position === REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_6
					}
				>
					<section className={pageUtils.classes.sectionNoPadding}>
						<h4 className={pageUtils.classes.h4}>
							{/* Travaux de branchement d'aqueduc et d'égouts */}
							{pageAssets?.request_form_branching_aqueduc_egouts}
						</h4>

						<RadioInput
							name={Q41IsWaterAndSewerConnectionRequiredInput.name}
							label={Q41IsWaterAndSewerConnectionRequiredInput.label}
							value={Q41IsWaterAndSewerConnectionRequiredInput.value}
							list={radioListYesNo}
							direction={Direction.vertical}
							hasError={errors.includes(
								Q41IsWaterAndSewerConnectionRequiredInput.name
							)}
							required={Q41IsWaterAndSewerConnectionRequiredInput.required}
							ref={
								Q41IsWaterAndSewerConnectionRequiredInput.ref as RefObject<HTMLInputElement>
							}
							hasBackgroundWrapper={true}
							onChange={onSetQ41IsWaterAndSewerConnectionRequiredInput}
						/>

						{Q41IsWaterAndSewerConnectionRequiredInput?.value?.toString() ==
							radioListYesNo[0].value && (
							<>
								<CheckboxInput
									name={Q41IsPropertyConnectedToMunicipalConduitsInput.name}
									label={Q41IsPropertyConnectedToMunicipalConduitsInput.label}
									description={
										Q41IsPropertyConnectedToMunicipalConduitsInput.description
									}
									value={`${Q41IsPropertyConnectedToMunicipalConduitsInput.value}`}
									list={radioListIsPropertyConnectedToMunicipalConduits}
									onChange={onSetQ41IsPropertyConnectedToMunicipalConduitsInput}
									required={
										Q41IsPropertyConnectedToMunicipalConduitsInput.required
									}
									hasError={errors.includes(
										Q41IsPropertyConnectedToMunicipalConduitsInput.name
									)}
									ref={
										Q41IsPropertyConnectedToMunicipalConduitsInput.ref as RefObject<HTMLInputElement>
									}
									errors={errors}
									shouldNotDivide
									hasBackgroundWrapper
									onFixError={onFixError}
									newCheckValue
									disabledValues={disabledMunicipalConduits}
								/>

								<TextAreaInput
									id={Q42AdditionalInfoInput.name}
									label={Q42AdditionalInfoInput.label}
									value={Q42AdditionalInfoInput.value}
									onChange={onSetQ42AdditionalInfoInput}
									hasError={errors.includes(Q42AdditionalInfoInput.name)}
									required={Q42AdditionalInfoInput.required}
									ref={
										Q42AdditionalInfoInput.ref as RefObject<HTMLTextAreaElement>
									}
									maxCharacters={100}
									rows={4}
								/>

								{isMoving(whatToDoInput.value) && (
									<>
										<CheckboxInput
											name={Q43PlannedWorkInput.name}
											label={Q43PlannedWorkInput.label}
											description={Q43PlannedWorkInput.description}
											value={`${Q43PlannedWorkInput.value}`}
											list={listPlannedWork}
											onChange={onSetQ43PlannedWorkInput}
											required={Q43PlannedWorkInput.required}
											hasError={errors.includes(Q43PlannedWorkInput.name)}
											ref={
												Q43PlannedWorkInput.ref as RefObject<HTMLInputElement>
											}
											errors={errors}
											shouldNotDivide
											hasBackgroundWrapper
											onFixError={onFixError}
											newCheckValue
										/>

										{Q43PlannedWorkInput.value.includes(
											listPlannedWork.slice(-1)[0].value
										) && (
											<TextAreaInput
												id={Q44AdditionalInfoInput.name}
												label={Q44AdditionalInfoInput.label}
												value={Q44AdditionalInfoInput.value}
												onChange={onSetQ44AdditionalInfoInput}
												hasError={errors.includes(Q44AdditionalInfoInput.name)}
												required={Q44AdditionalInfoInput.required}
												ref={
													Q44AdditionalInfoInput.ref as RefObject<HTMLTextAreaElement>
												}
												maxCharacters={100}
												rows={4}
											/>
										)}
									</>
								)}
							</>
						)}
					</section>
				</ReactCollapse>
			)}

			{subSteps?.position === REQUEST_TN_DETAILS_STEPS_NAME.DOCUMENTS && (
				<ReactCollapse
					isOpened={
						subSteps !== undefined &&
						subSteps.position === REQUEST_TN_DETAILS_STEPS_NAME.DOCUMENTS
					}
				>
					<section className={pageUtils.classes.sectionNoPadding}>
						<h4 className={pageUtils.classes.h4}>
							{pageAssets?.request_form_required_documents}
						</h4>

						<span className={pageUtils.classes.fileInfo}>
							{pageAssets?.page_requestInfo_inputFileDesc}
						</span>

						{isDemolition(whatToDoInput?.value) && (
							<>
								<RequestFiles
									subtitle={thirdQuestion.label}
									name={thirdQuestion.name}
									required={thirdQuestion.required}
									files={thirdQuestion.files}
									hasError={errors.includes(thirdQuestion.name)}
									removeSectionMarginTop
									onSetHasError={onSetErrorFromFileComponent}
									onSetFiles={onSelectFileThirdQuestion}
									subSteps={subSteps}
									maxFiles={thirdQuestion?.numberMaxFiles}
									description={thirdQuestion.description}
								/>

								<RequestFiles
									subtitle={fourthQuestion.label}
									name={fourthQuestion.name}
									required={fourthQuestion.required}
									files={fourthQuestion.files}
									hasError={errors.includes(fourthQuestion.name)}
									removeSectionMarginTop
									onSetHasError={onSetErrorFromFileComponent}
									onSetFiles={onSelectFileFourthQuestion}
									subSteps={subSteps}
									maxFiles={fourthQuestion?.numberMaxFiles}
									description={fourthQuestion.description}
								/>
							</>
						)}

						{isMoving(whatToDoInput.value) && (
							<>
								{renderMovingRequestFiles('required', false)}
								{renderMovingRequestFiles('optional', false)}
							</>
						)}
					</section>
				</ReactCollapse>
			)}
		</>
	)
}

export default BuildingDemolitionOrMovingPermitForm
